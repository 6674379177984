import { Header } from 'components'
import RegistrationStepper from 'components/RegistrationStepper'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

const MainLayout = ({
  showRegistrationStepper = false,
}: {
  showRegistrationStepper?: boolean
}): React.ReactElement => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])
  return (
    <>
      <div className="px-5">
        <Header />
      </div>
      {showRegistrationStepper && <RegistrationStepper />}
      <Outlet />
    </>
  )
}

export default MainLayout
