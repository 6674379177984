const momTest = require('moment')

export const getConsonanti = b => {
  let d = ''
  let c
  const a = 'bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ'
  for (c = 0; c < b.length; c += 1) {
    if (a.indexOf(b.substring(c, c + 1)) !== -1) {
      d += b.substring(c, c + 1)
    }
  }
  return d.toUpperCase()
}

export const getVocali = c => {
  let a = ''
  const b = 'bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ'
  for (let i = 0; i < c.length; i += 1) {
    if (
      b.indexOf(c.substring(i, i + 1)) === -1 &&
      c.substring(i, i + 1) !== ' ' &&
      c.substring(i, i + 1) !== "'"
    ) {
      a += c.substring(i, i + 1)
    }
  }
  return a.toUpperCase()
}

export const calcolaNome = nome => {
  let b = ''
  let a
  const cons = getConsonanti(nome)
  if (cons.length > 3) {
    b = cons.substring(0, 1) + cons.substring(2, 3) + cons.substring(3, 4)
  } else if (cons.length === 3) {
    b = cons
  } else {
    b = cons + getVocali(nome).substring(0, 3 - cons.length)
    if (b.length < 3) {
      for (a = b.length; a < 3; a += 1) {
        b += 'X'
      }
    }
  }
  return b
}

export const checkCodFisNome = (cf, nome) => {
  const c = cf.substring(3, 6).toUpperCase()
  if (calcolaNome(nome) !== c) {
    return false
  }
  return true
}

export const calcolaCognome = cognome => {
  let b = ''
  let a
  b = getConsonanti(cognome)
  if (b.length >= 3) {
    b = b.substring(0, 3)
  } else {
    b += getVocali(cognome).substring(0, 3 - b.length)
    if (b.length < 3) {
      for (a = b.length; a < 3; a += 1) {
        b += 'X'
      }
    }
  }
  return b
}

export const checkCodFisCognome = (cf, cognome) => {
  const a = cf.substring(0, 3).toUpperCase()
  if (calcolaCognome(cognome) !== a) {
    return false
  }
  return true
}

export const getIsMaggiorenne = codiceFiscaleInput => {
  const monthTable = {
    A: '01',
    B: '02',
    C: '03',
    D: '04',
    E: '05',
    H: '06',
    L: '07',
    M: '08',
    P: '09',
    R: '10',
    S: '11',
    T: '12',
  }

  const year = codiceFiscaleInput.substr(6, 2)
  const yearList = []
  const year19XX = parseInt(`19${year}`, 10)
  const year20XX = parseInt(`20${year}`, 10)
  // console.log('year19XX : '+year19XX);
  // console.log('year20XX : '+year20XX);
  const currentYear20XX = new Date().getFullYear()
  if (currentYear20XX - year20XX >= 0) {
    yearList.push(year20XX)
  } else {
    yearList.push(year19XX)
  }

  const monthChar = codiceFiscaleInput.substr(8, 1)

  let day = parseInt(codiceFiscaleInput.substr(9, 2), 10)
  if (day > 31) {
    day -= 40
  }

  const birthYear = parseInt(yearList, 10)

  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() + 1
  const currentDay = today.getDate()

  // console.log('currentYear : '+currentYear);
  // console.log('birthYear : '+birthYear);

  let birthDay = parseInt(day, 10)
  let birthMonth = monthChar.toUpperCase()

  const sesso = birthDay > 31 ? 'F' : 'M'
  if (sesso === 'F') birthDay -= 40

  if (!monthTable[birthMonth]) return 'mese di nascita errato'
  birthMonth = monthTable[birthMonth]

  // console.log('birthYear:' +birthYear);
  // console.log('sesso: '+sesso);
  // console.log('BIRTH DATE : ' +birthDay+'/'+birthMonth+'/'+birthYear);
  // console.log('CURRENT DATE : ' +currentDay+'/'+currentMonth+'/'+currentYear);

  const yeaDiff = currentYear - birthYear

  // console.log('età cliente: '+yeaDiff);

  if (yeaDiff <= 18) {
    if (yeaDiff === 18) {
      if (currentMonth === birthMonth) {
        // console.log('mese di nascita = mese corrente');
        if (currentDay < birthDay) {
          return 'non sei maggiorenne'
        }
      } else if (currentMonth <= birthMonth) {
        return 'non sei maggiorenne'
      }
    } else {
      return 'non sei maggiorenne'
    }
  }
  return ''
}

export const checkValidDate = (dd, mm, yyyy) => {
  const dateM = momTest(`${yyyy}-${mm}-${dd}`, 'YYYY-MM-DD')
  return dateM.isValid()
}

export const isCF = param => {
  if (
    /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(param)
  ) {
    return true
  }
  return false
}
