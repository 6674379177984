import { AxiosResponse } from 'axios'
import { LegalDoc, LegalDocFormat } from 'commons/dto/legalDoc'
import { easyPayInstance } from 'Axios/AxiosInstance'
import { isLexusUrlStatic } from 'utils/utils';

let appId = "TOYOTA"
let url = window.location.href;
let brand = "TOYOTA"
if(isLexusUrlStatic(url)){
  brand = "LEXUS"
}
  
export const getInformativeDoc = (
  docId: string,
  format: LegalDocFormat,
): Promise<AxiosResponse<LegalDoc>> =>
  easyPayInstance.get(`/${appId}/legalDoc/IT/${format}/${docId}/${brand}`)
