import React from 'react'
import AmericanExpressLogo from '../../logos/american-express.svg'
import MasterCardLogo from '../../logos/mastercard.svg'
import VisaLogo from '../../logos/visa.svg'
import CirrusLogo from '../../logos/cirrus.svg'
import DinersClubLogo from '../../logos/diners-club.svg'
import UnionPayLogo from '../../logos/unionpay.svg'
import MaestroLogo from '../../logos/maestro.svg'
import PagoBancomatLogo from '../../logos/pago-bancomat.svg'
import { Card as CardType } from 'store/slices/userSlice'
import Paragraf from 'components/Paragraf'
import CardStatus from 'components/CardStatus'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const creditCardDigitsMap = new Map<string, number>([
  ['visa', 16],
  ['mastercard', 16],
  ['american-express', 15],
  ['diners-club', 14],
  ['discover', 16],
  ['jcb', 16],
  ['unionpay', 16],
  ['maestro', 16],
  ['cirrus', 16],
  ['pago-bancomat', 16],
])

const creditCardLogoMap = new Map([
  ['visa', VisaLogo],
  ['visa-classic', VisaLogo],
  ['visa-business', VisaLogo],
  ['mastercard', MasterCardLogo],
  ['amex', AmericanExpressLogo],
  ['diners-club', DinersClubLogo],
  ['cirrus', CirrusLogo],
  ['pago-bancomat', PagoBancomatLogo],
  ['unionpay', UnionPayLogo],
  ['maestro', MaestroLogo],
])
const Card: React.VFC<{
  card: CardType
  onCardClick: () => void
  showStatus?: boolean
  cssProps?: string
}> = ({ card, onCardClick, showStatus = true, cssProps }) => {
  return (
    <button
      onClick={() => onCardClick()}
      className={`flex flex-row space-y-2 space-x-4 items-center ${cssProps} `}
    >
      {creditCardLogoMap.get(
        card?.brand?.replaceAll(' ', '-').toLowerCase(),
      ) ? (
        <img
          width="20%"
          src={creditCardLogoMap.get(
            card?.brand?.replaceAll(' ', '-').toLowerCase(),
          )}
          alt="logo"
        />
      ) : (
        <Paragraf
          cssProps={`text-left font-${urlisLexus[0]} `}
          name={card?.brand}
        />
      )}
      <div className="flex flex-col">
        <Paragraf
          cssProps={`font-${urlisLexus[0]} `}
          name={`${card?.lastDigits?.padStart(
            creditCardDigitsMap.get(card?.brand) ?? 16,
            '*',
          )} `}
        />
        <Paragraf
          cssProps="text-left"
          name={`${card?.expiryMonth}/${card?.expiryYear} `}
        />
      </div>
      {showStatus && <CardStatus card={card} />}
    </button>
  )
}

export default Card
