/* eslint-disable require-yield */
import {
  addressApi,
  cardsApi,
  informativeDocumentsApi,
  legalDocApi,
  paymentApi,
  phoneApi,
  subscriptionApi,
  tempUserApi,
  userApi,
  workOrdersApi
} from 'api/easypay'
import { insertEmail } from 'api/easypay/email'
import { loginApi } from 'api/keycloak'
import { AxiosResponse } from 'axios'
import { easyPayInstance } from 'Axios/AxiosInstance'
import { LOCAL_STORAGE_TOKEN_KEY,EXPIRED_CARD_MESSAGE, INVALID_CARD_MESSAGE } from 'commons/constants'
import { AddressResponse } from 'commons/dto/address'
import { LegalDoc, SubscribedDocuments } from 'commons/dto/legalDoc'
import { PhoneNumberResponse } from 'commons/dto/phoneNumber'
import { UserResponse } from 'commons/dto/user'
import { UserInfo } from 'commons/dto/userInfo'
import { KeycloakToken } from 'commons/types/keycloakToken'
import CryptoJS from 'crypto-js'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { LOGIN } from 'routes/constants'
import {
  CUSTOMER_AREA_HOMEPAGE,
  DIRECT_PAYMENT,
  EMAIL_SUBMISSION,
  PHONE_SUBMISSION
} from 'routes/constants'
import {
  addNewUserCard,
  offline,
  online,
  requestActivateSubscription,
  requestActivateUserCard,
  requestCancelUserCard,
  requestCreateSubscription,
  requestInformativeDocuments,
  requestOneShotPayment,
  requestPayInstalment,
  requestSuspendUserCard,
  requestUserActiveWorkOrders,
  requestUserCards,
  resetState
} from 'store/actions'
import {
  completeRegistrationProcess,
  getLegalDoc,
  getLegalDocSucceeded,
  populateWorkOrder,
  resetToInitialStateAppMisc,
  selectLegalDocs,
  selectWorkOrder,
  subscribeLegalDoc,
  subscribeToAllLegalDocs,
  toggleLoading
} from 'store/slices/appMiscSlice'
import {
  selectStepAmount,
  toggleStepVisibility
} from 'store/slices/registrationStepper'
import {
  confirmTempUserId,
  createSubscriptionSuccess,
  login,
  loginFailed,
  loginSucceeded,
  logOut,
  logOutRequest,
  resetToInitialStateUser,
  selectCurrentSubscription,
  selectTempUser,
  selectUserInfo,
  setDetailUserActiveWorkOrders,
  setSubscribedInformativeDocuments,
  setTempUserIdFailed,
  setTempUserIdRequest,
  setTempUserIdSucceded,
  setUserActiveWorkOrders,
  setUserCards,
  setUserMiscLoading
} from 'store/slices/userSlice'
import { LocalStorageService } from 'utils/LocalStorageService'
import { printToast } from 'utils/printToast'
import { UUID } from 'utils/uuidv4'
import { isLexusUrl } from 'utils/utils'
import { checkSubscribedDocuments } from 'api/easypay/informativeDocuments'

const handlePhoneNumber = (
  phoneInfo: PhoneNumberResponse,
): {
  entityId: string
  phoneNumber: string
} => {
  if (phoneInfo.length > 0) {
    const withDefaultFlagIdx = phoneInfo.findIndex(
      ({ phoneNumber }) => phoneNumber.defaultFlag,
    )
    const withConfirmedFlagIdx = phoneInfo.findIndex(
      ({ phoneNumber }) => phoneNumber.confirmedFlag,
    )
    if (withDefaultFlagIdx === withConfirmedFlagIdx)
      return {
        phoneNumber: phoneInfo[withConfirmedFlagIdx].phoneNumber.phoneNumber,
        entityId: phoneInfo[withConfirmedFlagIdx].entityId,
      }
  }
  return {
    phoneNumber: phoneInfo[0].phoneNumber.phoneNumber,
    entityId: phoneInfo[0].entityId,
  }
}

function* loginEffect(action) {
  const { email, password, navigate, urlParameters, token } = action.payload
  try {
    let accessToken;
    if (token == null || token == undefined) {
      const { data }: AxiosResponse<KeycloakToken> = yield call(
        loginApi.getToken,
        { email, password },
      )
      accessToken = data;
    }


    const decryptPassphrase = UUID()
    const s3c: string = CryptoJS.AES.encrypt(
      password,
      decryptPassphrase,
    ).toString()

    LocalStorageService.addObject({
      token: { ...(token ?? accessToken), email, s3c },
    })
    const { access_token } = token ?? accessToken;

    const { data: userInfo }: AxiosResponse<UserInfo> = yield call(
      loginApi.getUserInfo,
      access_token,
    )


    const { data: userData }: AxiosResponse<UserResponse> = yield call(
      userApi.getUser,
    )
    console.log("Userid: " + userInfo.userId)
    const { data: addressData }: AxiosResponse<AddressResponse> = yield call(
      addressApi.getAddress,
      userInfo.userId,
    )
    const { data: phoneResponse }: AxiosResponse<PhoneNumberResponse> =
      yield call(phoneApi.getPhoneNumber, userInfo.userId)
    const phoneData = handlePhoneNumber(phoneResponse)
    delete addressData[0].address.gps


    const parameters = {}
    const { data } = yield call(workOrdersApi.getActiveWorkOrders, {
      ...parameters,
    })

    if (data.length !== 0 && data.every(workOrder => workOrder?.carModel?.toLowerCase().includes("lexus") || workOrder?.workOrder?.includes("LCU"))) {
      localStorage.setItem("isOnlyLexus", "true");
      window.dispatchEvent(new Event('storage'))
    } else {
      localStorage.setItem("isOnlyLexus", "false");
      window.dispatchEvent(new Event('storage'))
    }
    /*
      Il codice sotto serve per settare isOnlyLexus a false se dopo aver effettuato la login sta pagando un TCU 
      l'esempio pratico è quando ho un account con un solo LCU e poi pago un tcu, quando mi riporta sulla schermata finale setta in automatico lexus a true, noi invece
      in questo caso non la vogliamo perché sappiamo di aver inserito un tcu
    */
    const easyPayFromSessionStorage = sessionStorage.getItem("easyPay")
    if (easyPayFromSessionStorage != null && (easyPayFromSessionStorage.includes("wordOrder") && easyPayFromSessionStorage.includes("LCU"))) {
      localStorage.setItem("isOnlyLexus", "true");
    }
    /*
      è giusto che ci sia invoice in tutti e due i lati dell'if, tu che leggerai dal futuro: fidati!
      il sessionstorage easyPay può non contenere TCU,invoice e LCU, in quel caso contiene solo il token con questi controlli noi andiamo ad evitare che un utente abbia la mail con queste 3 parole!
    */
    if (easyPayFromSessionStorage != null && ((easyPayFromSessionStorage.includes("wordOrder") || easyPayFromSessionStorage.includes("invoice")) &&
      (easyPayFromSessionStorage.includes("TCU") || easyPayFromSessionStorage.includes("invoice")))) {
      localStorage.setItem("isOnlyLexus", "false");
    }
    yield put(
      loginSucceeded({
        decryptPassphrase,
        userInfo: {
          userId: userInfo.userId,
          preferred_username: userInfo.preferred_username,
          email: userInfo.email,
          email_verified: userInfo.email_verified,
          given_name: userInfo.given_name,
          ...userData[0].individual.personalInfo,
        },
        addressInfo: {
          addressId: addressData[0].id,
          defaultFlag: addressData[0].defaultFlag,
          ...addressData[0].address,
        },
        phoneInfo: { ...phoneData },
        navigate,
      }),
    )
    if (
      (urlParameters !== null && urlParameters === 'split-payment')
    ) {
      yield put(setUserMiscLoading(true))
      yield call(checkSubscribedDocuments)
      yield put(setUserMiscLoading(false))
      yield call(requestActivateSubscriptionEffect)
    } else {
      yield call(navigate, `/customer-area/${CUSTOMER_AREA_HOMEPAGE}`)
    }
  } catch (e) {
    console.error(e)
    if (e?.response?.status === 401) {
      yield call(printToast.error, 'Le credenziali di accesso non sono valide')
    } else if (e?.response?.data?.messages[0]?.code == "INADEMPIENTE_TOTALE") {
      yield call(printToast.error, "L'utente inserito è considerato come inadempiente totale")
    } else {
      yield call(printToast.error, 'Errore di comunicazione')
    }
    yield put(loginFailed())
  }
}

function* getLegalDocEffect(action) {
  const { documentId, documentFormat, navigate } = action.payload
  try {
    const { data: document }: AxiosResponse<LegalDoc> = yield call(
      legalDocApi.getInformativeDoc,
      documentId,
      documentFormat,
    )
    document.docBase64=null
    yield put(
      getLegalDocSucceeded({
        documentId,
        document: { ...document, content: '' },
      }),
    )
  } catch (e) {
    console.error(e)
    printToast.error(
      'Non è stato possibile recuperare il documento. Riprovare più tardi.',
      5000,
      () => navigate('/'),
    )
  }
}

function* subscribeLegalDocEffect(action) {
  const { documentId, targetPath, errorTargetPath, navigate } = action.payload
  const tempUser: ReturnType<typeof selectTempUser> = yield select(
    selectTempUser,
  )
  yield put(toggleLoading({ value: true }))
  try {
    yield call(
      informativeDocumentsApi.subscribeDocuments,
      documentId,
      tempUser.id,
    )
    if (targetPath) navigate(targetPath)
  } catch (e) {
    console.error(e)
    printToast.error(undefined, 5000, () => navigate(errorTargetPath || '/'))
  } finally {
    yield put(toggleLoading({ value: false }))
  }
}
function* subscribeToAllLegalDocsEffect(action) {
  const { navigate } = action.payload
  try {
    yield all([
      call(
        getLegalDocEffect,
        getLegalDoc({
          documentId: 'PRIVACY',
          documentFormat: 'HTML',
          navigate,
        }),
      ),
      call(
        getLegalDocEffect,
        getLegalDoc({
          documentId: 'INFORMATIVE',
          documentFormat: 'HTML',
          navigate,
        }),
      ),
      call(
        getLegalDocEffect,
        getLegalDoc({
          documentId: 'TERMS',
          documentFormat: 'HTML',
          navigate,
        }),
      ),
    ])
    const legalDocs = yield select(selectLegalDocs)
    const privacyDocId = legalDocs['PRIVACY'].content.documentId
    const termsAndConditionsDocId = legalDocs['TERMS'].content.documentId
    const informativeDocId = legalDocs['INFORMATIVE'].content.documentId
    yield call(
      subscribeLegalDocEffect,
      subscribeLegalDoc({
        documentId: privacyDocId,
        targetPath: undefined,
        navigate,
      }),
    )
    yield call(
      subscribeLegalDocEffect,
      subscribeLegalDoc({
        documentId: informativeDocId,
        targetPath: undefined,
        navigate,
      }),
    )
    yield call(
      subscribeLegalDocEffect,
      subscribeLegalDoc({
        documentId: termsAndConditionsDocId,
        targetPath: undefined,
        navigate,
      }),
    )
  } catch (e) {
    console.error(e)
    printToast.error(undefined, 5000, () => navigate('/'))
  }
}

function* completeRegistrationEffect(action) {
  const { navigate } = action.payload
  const workOrder = yield select(selectWorkOrder)
  let brandName = "TOYOTA"
  let url = window.location.href;
  if (url.includes("lexus")) {
    brandName = "LEXUS"
  }
  try {
    const tempUser: ReturnType<typeof selectTempUser> = yield select(
      selectTempUser,
    )
  
    const { data } = yield call(tempUserApi.confirmTempUserId, tempUser.id, brandName,workOrder.email)
    const userId = data.userId
    yield put(confirmTempUserId(userId))
    yield call(requestActivateSubscriptionEffect)
  } catch (e) {
    console.error(e)
    printToast.error(
      'Non è stato possibile completare la registrazione. Riprovare più tardi.',
      5000,
      () => navigate('/'),
    )
  }
}

function* requestTemporaryUserIdEffect(action) {
  const { navigate } = action.payload
  var registeredEmail = false;

  try {
    const workOrder = yield select(selectWorkOrder)
    const {
      data: { tempUserId },
    } = yield call(tempUserApi.fetchTempUser,workOrder.tempUserId,workOrder.email,workOrder.phone)
    yield put(setTempUserIdSucceded(tempUserId))
    const stepsAmount = yield select(selectStepAmount)
    if (stepsAmount === 4) {
      // skip email email submission
      const workOrder = yield select(selectWorkOrder)
      try {
        let brand = "TOYOTA"

        let url = window.location.href;
        isLexusUrl(url).then(isLexus => {
          if (isLexus) {
            brand = "LEXUS"
          }
        })
        yield call(insertEmail, tempUserId, workOrder.email, true, brand, true)
      } catch (e) {
        console.error(e)
        const message = JSON.parse(e?.response?.data?.messages[0]?.message)
        if (message.code === 'USED_EMAIL') {
          printToast.error(message.message, 6000)
        } else {
          printToast.error(
            "Si è verificato un errore, si prega di riprovare più tardi.",
            5000,
          )
        }
        registeredEmail = true;
      }

      if (registeredEmail) {
        yield call(navigate, `/${LOGIN}?redirectReason=split-payment`)
        navigate(`/${LOGIN}?redirectReason=split-payment`, { replace: true })
      } else {
        yield call(
          subscribeToAllLegalDocsEffect,
          subscribeToAllLegalDocs({ navigate }),
        )
        yield call(navigate, `/registration/${PHONE_SUBMISSION}`)
        navigate(`/registration/${PHONE_SUBMISSION}`)
      }

    } else {
      yield call(navigate, `/registration/${EMAIL_SUBMISSION}`)
      navigate(`/registration/${EMAIL_SUBMISSION}`)
    }
  } catch (e) {
    console.error(e)
    yield put(setTempUserIdFailed(e.message))
  } finally {
    yield put(toggleStepVisibility(true))
  }
}

function* logOutEffect(action) {
  const { targetPath = '/', navigate } = action.payload
  sessionStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
  navigate(targetPath, { replace: true })
  yield put(populateWorkOrder({ workOrder: null, status: null }))
  yield put(logOut())
}

function* requestOneShotPaymentEffect(action) {
  try {
    yield put(toggleLoading({ value: false }))
    const { data } = yield call(paymentApi.oneShotPayment, action.payload)
    window.location.replace(data.redirectUrl)
  } catch (e) {
    var message = JSON.parse(e?.response?.data?.messages[0]?.message)
    console.log(message)

    if (message?.message == "There is a successful payment for this work order/installment") {
      window.location.replace(`/thank-you-financial`)
    } else {
      printToast.error(
        'Non è stato possibile effettuare il pagamento. Riprovare più tardi.',
        5000,
      )
    }
  } finally {
    yield put(toggleLoading({ value: false }))
  }
}

function* requestUserCardsEffect() {
  try {
    yield put(setUserMiscLoading(true))
    const userInfo = yield select(selectUserInfo)
    const { data } = yield call(cardsApi.getCards, userInfo.userId)
    yield put(setUserCards(data))
  } catch (e) {
    printToast.error(
      'Non è stato possibile recuperare le carte di credito. Riprovare più tardi.',
      5000,
    )
  } finally {
    yield put(setUserMiscLoading(false))
  }
}

function* requestActivateUserCardEffect(action) {
  try {
    yield put(setUserMiscLoading(true))
    yield call(cardsApi.activateCard, action.payload.cardId)
    yield call(requestUserCardsEffect)
  } catch (e) {
    printToast.error(
      'Non è stato possibile attivare la carta di credito. Riprovare più tardi.',
      5000,
    )
  } finally {
    yield put(setUserMiscLoading(false))
  }
}
function* requestSuspendUserCardEffect(action) {
  try {
    yield put(setUserMiscLoading(true))
    yield call(cardsApi.suspendCard, action.payload.cardId)
    yield call(requestUserCardsEffect)
  } catch (e) {
    printToast.error(
      'Non è stato possibile sospendere la carta di credito. Riprovare più tardi.',
      5000,
    )
  } finally {
    yield put(setUserMiscLoading(false))
  }
}
function* requestCancelUserCardEffect(action) {
  try {
    yield put(setUserMiscLoading(true))
    yield call(cardsApi.cancelCard, action.payload.cardId)
    yield call(requestUserCardsEffect)
  } catch (e) {
    printToast.error(
      'Non è stato possibile cancellare la carta di credito. Riprovare più tardi.',
      5000,
    )
  } finally {
    yield put(setUserMiscLoading(false))
  }
}

function* addUserCardsEffect() {
  try {
    yield put(setUserMiscLoading(true))
    const userInfo = yield select(selectUserInfo)
    const { data } = yield call(cardsApi.addNewCard, userInfo.userId)
    window.location.replace(data.url)
  } catch (e) { 
    console.log(e);
    if (e?.response?.data?.messages[0]?.message !== null) {
      if (e?.response?.data?.messages[0]?.message === 'INVALID_CARD') {
        printToast.error(INVALID_CARD_MESSAGE)
      } else if (e?.response?.data?.messages[0]?.message === 'EXPIRED_CARD') {
        printToast.error(EXPIRED_CARD_MESSAGE)
      } else {
        printToast.error(
          'Non è stato possibile aggiungere una nuova carta di credito. Riprovare più tardi. ',
        )
      }
    }
  } finally {
    yield put(setUserMiscLoading(false))
  }
}

function* requestCreateSubscriptionEffect(action) {
  try {
    yield put(setUserMiscLoading(true))
    const { data } = yield call(
      subscriptionApi.createSubscription,
      action.payload,
    )
    yield put(createSubscriptionSuccess(data))
  } catch (e) {
    sessionStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    yield put(populateWorkOrder({ workOrder: null, status: null }))
    yield put(logOut())
    if (
      Array.isArray(e?.response?.data?.messages) &&
      'message' in e?.response?.data?.messages[0]
    ) {
      if (e?.response?.data?.messages[0]?.message === "BAD_AMOUNT") {
        printToast.error("Non è stato possibile creare la sottoscrizione, l'importo è inferiore a 0.03€", 5000);
      } else {
        printToast.error(e?.response?.data?.messages[0]?.message, 5000);
      }
    } else {
      printToast.error(
        'Non è stato possibile creare la sottoscrizione. Riprovare più tardi.',
        5000,
      )
    }
  } finally {
    yield put(setUserMiscLoading(false))
  }
}

function* requestActivateSubscriptionEffect() {
  try {
    yield put(setUserMiscLoading(true))
    const userInfo = yield select(selectUserInfo)
    const currentSubscription = yield select(selectCurrentSubscription)
    const { data } = yield call(subscriptionApi.subscriptionExecution, {
      subscriptionId: currentSubscription.subscriptionId,
      customerId: userInfo.userId,
    })
    yield put(createSubscriptionSuccess(null))
    window.location.replace(data.redirectUrl)
  } catch (error) {
    const message = error?.response?.data?.messages[0]?.message
    console.log(message)
    if (message === 'BAD_BUYER') {
      printToast.error(
        'Non ti è stato permesso di effettuare il pagamento a rate, puoi procedere con un pagamento diretto',
        6000,
        () => window.location.replace(`/${DIRECT_PAYMENT}`),
      )
    } else if (message == "There is a successful payment for this work order/installment") {
      window.location.replace(`/thank-you-financial`)
    } else if (
      Array.isArray(error?.response?.data?.messages) &&
      'message' in error?.response?.data?.messages[0]
    ) {
      printToast.error(error?.response?.data?.messages[0]?.message, 5000)
    } else {
      printToast.error(
        'Non è stato possibile attivare la sottoscrizione. Riprovare più tardi.',
        5000,
      )
    }
  } finally {
    yield put(createSubscriptionSuccess(null))
    yield put(setUserMiscLoading(false))
  }
}

function* requestInformativeDocumentsEffect() {
  try {
    yield put(setUserMiscLoading(true))

    const { data }: AxiosResponse<SubscribedDocuments> = yield call(
      informativeDocumentsApi.getSubscribedDocuments,
    )

    yield put(setSubscribedInformativeDocuments(data))
  } catch (e) {
    console.log({ e })
    printToast.error(
      'Non è stato possibile recuperare le informazioni necessarie per trovare documenti a te associati. Riprovare più tardi.',
      5000,
    )
  } finally {
    yield put(setUserMiscLoading(false))
  }
}
function* requestUserActiveWorkOrdersEffect(action) {
  const { from, to } = action.payload
  try {
    yield put(setUserMiscLoading(true))
    const parameters = from && to ? { from, to } : {}
    const { data } = yield call(workOrdersApi.getActiveWorkOrders, {
      ...parameters,
    })
    yield put(setUserActiveWorkOrders(data))
    const promises = data.map(workOrder =>{
      if(workOrder.subscriptionId!=undefined){
      return paymentApi.getInstallmentDetail({
        subscriptionId: workOrder.subscriptionId,
      })}else{
        return {data:{...workOrder}};
      }
    
    }
    )
  
    const response = yield all([...promises])
    const responseData = response.map(res => res.data)
    yield put(setDetailUserActiveWorkOrders(responseData))
  } catch (e) {
    console.log({ e })
    printToast.error(
      e,
      5000,
    )
  } finally {
    yield put(setUserMiscLoading(false))
  }
}

function* requestPayInstalmentEffect(action) {
  try {
    yield put(setUserMiscLoading(true))
    const userInfo = yield select(selectUserInfo)
    const { data } = yield call(paymentApi.payInstalment, {
      customerId: userInfo.userId,
      ...action.payload,
    })
    window.location.replace(data.redirectUrl)
  } catch (e) {
    var message = JSON.parse(e?.response?.data?.messages[0]?.message)
    console.log(message)
    if (message?.message == "There is a successful payment for this work order/installment") {
      window.location.replace(`/thank-you-financial`)
    } else {
      printToast.error(
        'Non è stato possibile effettuare il pagamento. Riprovare più tardi.',
        5000,
      )
    }

  } finally {
    yield put(setUserMiscLoading(false))
  }
}

function* offlineEffect() {
  printToast.error(
    'Attenzione! risulti offline',
    5000,
    () => undefined,
    'connection-lost',
  )
}
function* onlineEffect() {
  printToast.success(
    'Connessione ripristinata!',
    5000,
    () => undefined,
    'connection-restored',
  )
}

function* resetStateEffect() {
  yield put(resetToInitialStateAppMisc())
  yield put(resetToInitialStateUser())
}

export default function* () {
  yield all([
    takeLatest(login.type, loginEffect),
    takeLatest(logOutRequest.type, logOutEffect),
    takeLatest(getLegalDoc.type, getLegalDocEffect),
    takeLatest(subscribeLegalDoc.type, subscribeLegalDocEffect),
    takeLatest(subscribeToAllLegalDocs.type, subscribeToAllLegalDocsEffect),
    takeLatest(completeRegistrationProcess.type, completeRegistrationEffect),
    takeLatest(setTempUserIdRequest.type, requestTemporaryUserIdEffect),
    takeLatest(requestOneShotPayment.type, requestOneShotPaymentEffect),
    takeLatest(requestUserCards.type, requestUserCardsEffect),
    takeLatest(requestActivateUserCard.type, requestActivateUserCardEffect),
    takeLatest(requestSuspendUserCard.type, requestSuspendUserCardEffect),
    takeLatest(requestCancelUserCard.type, requestCancelUserCardEffect),
    takeLatest(addNewUserCard.type, addUserCardsEffect),
    takeLatest(requestCreateSubscription.type, requestCreateSubscriptionEffect),
    takeLatest(
      requestActivateSubscription.type,
      requestActivateSubscriptionEffect,
    ),
    takeLatest(
      requestUserActiveWorkOrders.type,
      requestUserActiveWorkOrdersEffect,
    ),
    takeLatest(
      requestInformativeDocuments.type,
      requestInformativeDocumentsEffect,
    ),
    takeLatest(requestPayInstalment.type, requestPayInstalmentEffect),
    takeLatest(offline.type, offlineEffect),
    takeLatest(online.type, onlineEffect),
    takeLatest(resetState.type, resetStateEffect),
  ])
}
