import { emailApi } from 'api/easypay'
import FormConfirmEmail from 'components/FormConfirmEmail'
import FormPinPad from 'components/FormPinPad'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggleLoading } from 'store/slices/appMiscSlice'
import {
  logOutRequest,
  selectUserInfo,
  setUserEmail,
} from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'
import { getBrandByURL } from 'utils/utils.js'


const UpdateEmail: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { email: userEmail, userId } = useSelector(selectUserInfo)
  const [section, setSection] = React.useState<'form' | 'confirm-code'>('form')
  const [newEmail, setNewEmail] = React.useState<string>(null)
  

  var url = window.location.href
  let brand = ''
  getBrandByURL(url).then(isLexus => {
    brand=isLexus
  })
  
  const onSubmitConfirmEmail = async ({ email, confirmEmail }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      await emailApi.updateEmail(userEmail, confirmEmail, brand)
      setNewEmail(confirmEmail)
      setSection('confirm-code')
    } catch (e) {
      const message = JSON.parse(e?.response?.data?.messages[0]?.message)
      if (message.code === 'USED_EMAIL') {
        printToast.error(message.message, 6000)
      } else {
        printToast.error(
          "Qualcosa è andato storto, prova a reinserire l'email",
          5000,
        )
      }
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const onConfirmCode = async ({ confirmCode }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      await emailApi.confirmEmail(userId, confirmCode, newEmail)
      dispatch(setUserEmail({ email: newEmail }))
      printToast.success('email aggiornata con successo!')
      navigate('/customer-area/homepage')
    } catch (e) {
      console.error(e)
      printToast.error(undefined, undefined, () =>
        dispatch(logOutRequest({ targetPath: '/', navigate })),
      )
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const onResendOtp = async () => {
    dispatch(toggleLoading({ value: true }))
    try {
      await emailApi.resendOtpEmail(userId, userEmail, brand)
      printToast.success(
        'Richiesta inviata. Controlla la tua casella di posta.',
      )
    } catch (e) {
      console.error(e)
      printToast.error(
        `Errore nell' invio della richiesta. Riprovare più tardi.`,
      )
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const handleFormPinPadGoBack = () => {
    setSection('form')
  }

  const RenderSectionsMap = new Map([
    [
      'form',
      <FormConfirmEmail
        key="form"
        workOrderEmail={userEmail}
        handleConfirmEmailSubmit={onSubmitConfirmEmail}
        isUpdatingEmail
      />,
    ],
    [
      'confirm-code',
      <FormPinPad
        key="confirm-code"
        header="Conferma codice"
        subHeader={`Inserisci il codice di conferma che hai ricevuto all'indirizzo ${newEmail} `}
        onPinPadSubmit={onConfirmCode}
        onResendConfirmationCode={onResendOtp}
        onGoBack={handleFormPinPadGoBack}
      />,
    ],
  ])

  return RenderSectionsMap.get(section)
}

export default UpdateEmail
