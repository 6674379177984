import {
  checkCodFisCognome,
  checkCodFisNome,
  getIsMaggiorenne,
} from './cfUtility'
import { checkCodeChars, checkCodeEven, checkCodeOdd } from './const'

// ----------------- Hook Form Validation ---------------

export const formatErrorLetters = value => {
  return (
    /^[A-Za-ëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ][a-zA-Z'áàâåãäæéèêëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ ]*$/.test(
      value,
    ) || 'Formato non valido'
  )
}

export const formatErrorPhoneNumber = value => {
  if (value) {
    return /^\d{9,11}$/.test(value) || 'Formato non valido'
  }
  return null
}

export const formatErrorSiret = value => {
  return /^\d{14}$/.test(value) || 'Formato non valido'
}

export const formatErrorApec = value => {
  return value.length === 5 || 'Formato non valido'
}

export const formatErrorEmail = value => {
  return (
    /^[a-zA-Z0-9]([a-zA-Z0-9._-]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|[0-9]{1,3})$/.test(
      value,
    ) || 'Formato non valido'
  )
}

export const emailNotSame = (emailConfirmation, value) => {
  if (value.trim().toLowerCase() !== emailConfirmation.trim().toLowerCase()) {
    return 'Email non uguale'
  }

  return null
}

export const formatNameCf = (fiscalCode, value) => {
  if (fiscalCode) {
    return checkCodFisNome(fiscalCode, value) || 'Nome non conforme'
  }
  return null
}

export const formatSurnameCf = (fiscalCode, value) => {
  if (fiscalCode) {
    return checkCodFisCognome(fiscalCode, value) || 'Cognome non conforme'
  }
  return null
}

export const formatErrorCodeFisc = value => {
  return (
    /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(
      value,
    ) || 'Formato non valido'
  )
}

export const formatErrorCalculatingCodeFisc = value => {
  const expectedCheckCode = value.charAt(15)
  const cf = value.slice(0, 15).toUpperCase()
  let val = 0
  for (let i = 0; i < 15; i += 1) {
    const c = cf[i]
    val += i % 2 !== 0 ? checkCodeEven[c] : checkCodeOdd[c]
  }
  val %= 26
  if (checkCodeChars.charAt(val) === expectedCheckCode.toUpperCase()) {
    return null
  }
  return 'Formato non valido'
}

export const ageErrorCodeFisc = value => {
  return getIsMaggiorenne(value) === '' || 'Formato non valido'
}

export const partitaIvaCheck = (value, _formaGiuridica, _codFiscale) => {
  if (!value.length) {
    return 'Formato non valido'
  }

  if (!/^\d{11}$/.test(value)) {
    return 'Formato non valido'
  }

  let sum = 0
  let control = 0
  let result = 0
  for (let i = 0; i < 10; i += 1) {
    if ((i + 1) % 2 !== 0) {
      sum += parseInt(value.charAt(i), 10)
    } else {
      const foundChar = parseInt(value.charAt(i), 10) * 2
      if (foundChar > 9) {
        sum += foundChar - 9
      } else {
        sum += foundChar
      }
    }
  }

  result = sum % 10
  if (result !== 0) {
    control = 10 - result
  }

  if (control !== parseInt(value.charAt(10), 10)) {
    return 'Formato non valido'
  }

  return null
}
