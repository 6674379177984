import CommonButton from 'components/CommonButton'
import Paragraf from 'components/Paragraf'
import React from 'react'
import { Card } from 'store/slices/userSlice'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const CardActions: React.VFC<{
  card: Card
  onSuspendCard: () => void
  onCancelCard: () => void
  onActivateCard: () => void
}> = ({ card, onActivateCard, onCancelCard, onSuspendCard }) => {
  const cardActionsMap = new Map([
    [
      'ACTIVE',
      [
        {
          text: 'Sospendi carta',
          emit: onSuspendCard,
        },
        // {
        //   text: 'Cancella carta',
        //   emit: onCancelCard,
        // },
      ],
    ],
    [
      'SUSPENDED',
      [
        {
          text: 'Riattiva carta',
          emit: onActivateCard,
        },
      ],
    ],
    ['CANCELLED', []],
    ['EXPIRED', []],
  ])
  return (
    <div className="flex flex-col items-center justify-center space-y-3">
      {cardActionsMap.get(card.status)?.length <= 0 && (
        <Paragraf
          cssProps={`text-center font-${urlisLexus[0]} `}
          name="Nessuna azione disponibile per questa carta"
        />
      )}
      {cardActionsMap.get(card.status)?.map(action => (
        <CommonButton
          key={action.text}
          onClick={action.emit}
          type="button"
          name={action.text}
        />
      ))}
    </div>
  )
}

export default CardActions
