import { phoneApi } from 'api/easypay'
import { __DEV__ } from 'commons/constants'
import FormConfirmPhone from 'components/FormConfirmPhone'
import FormPinPad from 'components/FormPinPad'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectWorkOrder, toggleLoading } from 'store/slices/appMiscSlice'
import {
  decrementCurrentStep,
  incrementCurrentStep,
} from 'store/slices/registrationStepper'
import { selectTempUser } from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'

const PhoneSubmission: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [section, setSection] = useState<'form' | 'confirm-code'>('form')
  const [phoneNumber, setPhoneNumber] = useState('')
  const { id: tempUserId } = useSelector(selectTempUser)
  const { phone } = useSelector(selectWorkOrder)

  const [deletingPhone, setDeletingPhone] = useState<string>(null)

  const handlePhoneSubmit = async ({ phone }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      await phoneApi.insertPhoneNumber(tempUserId, phone, true)
      setPhoneNumber(phone ?? phone.replace(" ", ""))
      setSection('confirm-code')
    } catch (e) {
      const message = JSON.parse(e?.response?.data?.messages[0]?.message)
      if (message.code === 'USED_PHONE_NUMBER') {
        printToast.error(message.message, 6000)
      } else {
        printToast.error(
          'Qualcosa è andato storto, prova a reinserire il numero di telefono',
          5000,
        )
      }
    } finally {
      dispatch(incrementCurrentStep())
      dispatch(toggleLoading({ value: false }))
    }
  }

  const handleOtpSubmit = async ({ confirmCode }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      await phoneApi.confirmPhoneNumber(tempUserId, confirmCode.trim(), phoneNumber)
      navigate('/registration/personal-info-submission')
    } catch (e) {
      console.error(e)
      try {
        const message = JSON.parse(e?.response?.data?.messages[0]?.message)
        if (message?.code === "04") {
          printToast.error(message.message, 6000)
        }else {        
            printToast.error('Qualcosa è andato storto',5000,)
          }
      }catch(e) {
        console.error("Could not parse " + e)
        printToast.error(
          'Qualcosa è andato storto',
          5000,
        )
      }
    } finally {
      dispatch(incrementCurrentStep())
      dispatch(toggleLoading({ value: false }))
    }
  }

  const onResendOtp = async () => {
    dispatch(toggleLoading({ value: true }))
    try {
      await phoneApi.resendOtpPhoneNumber(tempUserId, phoneNumber)
      printToast.success('codice di conferma inviato nuovamente')
    } catch (e) {
      console.error(e)
      printToast.error(
        `Errore nell' invio della richiesta. Riprovare più tardi.`,
      )
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const handleFormPinPadGoBack = () => {
    dispatch(decrementCurrentStep())
    setSection('form')
  }

  const RenderSectionsMap = new Map([
    [
      'form',
      <FormConfirmPhone
        key="form"
        initialPhoneNumber={phone}
        handleConfirmPhoneSubmit={handlePhoneSubmit}
      />,
    ],
    [
      'confirm-code',
      <FormPinPad
        key="confirm-code"
        header="Conferma codice"
        subHeader={`Inserisci il codice di conferma che hai ricevuto al numero ${phoneNumber} `}
        onPinPadSubmit={handleOtpSubmit}
        onResendConfirmationCode={onResendOtp}
        onGoBack={handleFormPinPadGoBack}
      />,
    ],
  ])

  if (__DEV__ && section === 'form')
    return (
      <>
        <div
          style={{
            border: '1px solid #FF0000',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={async () => {
            await phoneApi.deletePhone(deletingPhone)
          }}
        >
          <input
            placeholder="Insert phone number (remember +39)"
            onChange={e => setDeletingPhone(e.target.value)}
          />
          <strong>DELETE PHONE</strong>
        </div>
        {RenderSectionsMap.get(section)}
      </>
    )
  return RenderSectionsMap.get(section)
}

export default PhoneSubmission
