import { AxiosResponse } from 'axios'
import { PhoneNumberResponse } from 'commons/dto/phoneNumber'
import { easyPayInstance } from 'Axios/AxiosInstance'

let appId = "TOYOTA"
let url = window.location.href;

export const getPhoneNumber = (
  userId: string,
  number?: string,
): Promise<AxiosResponse<PhoneNumberResponse>> =>
  easyPayInstance.get(`/${appId}/phoneNumber/`, {
    params: {
      userId,
      number,
    },
  })

export const insertPhoneNumber = (
  userId: string,
  phoneNumber?: string,
  defaultFlag?: boolean,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.post(`/${appId}/phoneNumber/`, {
    userId,
    phoneNumber,
    defaultFlag,
  })

export const confirmPhoneNumber = (
  userId: string,
  confirmCode: string,
  phoneNumber: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/phoneNumber/${userId}/confirm`, {
    confirmCode,
    phoneNumber,
  })

export const updatePhoneNumber = (
  previousPhoneNumber: string,
  newPhoneNumber: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.put(`/${appId}/phoneNumber/`, {
    previousPhoneNumber,
    newPhoneNumber,
  })

export const resendOtpPhoneNumber = (
  userId: string,
  phoneNumber: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/phoneNumber/${userId}/resend-otp`, {
    phoneNumber,
  })

export const deletePhone = (
  phoneNumber: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.delete(`/${appId}/phoneNumber/${phoneNumber}`)
