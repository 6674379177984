import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import Paragraf from 'components/Paragraf'
import { useQuery } from 'Hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LOGIN } from 'routes/constants'
import { RootState } from 'store'
import { login } from 'store/slices/userSlice'
import { formatErrorEmail } from 'utils/formValidation'
import { useCookies } from 'react-cookie';
import GoogleSocialLogin from './GoogleSocialLogin'
import Cookies from 'js-cookie'
import AppleSocialLogin from './AppleSocialLogin'
import FacebookSocialLogin from './FacebookSocialLogin'
import { loadConstants } from 'api/api'
import { isLexusCss } from 'utils/utils'
import { Icon } from 'react-icons-kit'
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
let urlisLexus = []
let url = window.location.href;
isLexusCss(url).then(isLexus => {
  urlisLexus = isLexus
})

const Login: React.FC = () => {
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState('password');
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies();
  const [socialLoginFbEnabled, setSocialLoginFbEnabled] = useState<string>()
  const [socialLoginGEnabled, setSocialLoginGEnabled] = useState<string>()

  const [socialLoginAppleEnabled, setSocialLoginAppleEnabled] = useState<string>()

  const [splitPaymentRedirect, setSplitPaymentRedirect] = useState(
    window.location.href.includes('redirectReason=split-payment'),
  )
  const {
    misc: { loading, error },
  } = useSelector((state: RootState) => state.user)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  })
  const query = useQuery()

  const sendLoginForm = async ({
    email,
    password,
    token
  }: {
    email: string
    password: string
    token: string
  }) => {
    dispatch(
      login({
        email,
        password,
        navigate,
        urlParameters: query.get('redirectReason'),
        token: token
      }),
    )
  }


  useEffect(() => {

    Cookies.remove("G_AUTHUSER_H")

    const getSocialLoginGEnabled = async () => {
      const temp = (await loadConstants()).SOCIAL_LOGIN_GOOGLE_ENABLED
      setSocialLoginGEnabled(temp)
    }
    const getSocialLoginFbEnabled = async () => {
      const temp = (await loadConstants()).SOCIAL_LOGIN_FACEBOOK_ENABLED
      setSocialLoginFbEnabled(temp)
    }
    const getSocialLoginAppleEnabled = async () => {
      const temp = (await loadConstants()).SOCIAL_LOGIN_APPLE_ENABLED
      setSocialLoginAppleEnabled(temp)
    }

    getSocialLoginFbEnabled()
    getSocialLoginGEnabled()
    getSocialLoginAppleEnabled()

    localStorage.clear()
    //ovviamente anche il setItem va eliminato

  }, [])

  useEffect(() => {
    const sessionExpired = JSON.parse(query.get('session-expired'))
    if (sessionExpired) {
      navigate(`/${LOGIN}`, { replace: true })
      toast.error('Sessione scaduta')
    }
  }, [])


  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      {splitPaymentRedirect && (
        <div>
          <span className={`text-center pl-2 pr-2 text-base text-secondary font-${urlisLexus[0]} `}>
            Non hai un account?
          </span>
          <div className="mb-2 mt-8 text-center w-full ">
            <CommonButton
              name="Registrati"
              loadingApi={loading}
              onClick={() => navigate('/registration')}
            ></CommonButton>
          </div>
          <hr className="my-4 block" />
        </div>
      )}
      <form onSubmit={handleSubmit(sendLoginForm)} noValidate>
        {splitPaymentRedirect && (
          <Paragraf cssProps="bold" name="Già registrato/a con Toyota o Lexus? Inserisci le tue credenziali per accedere alla tua area privata. Altrimenti utilizza il pulsante 'Registrati'" />
        )}
        {!splitPaymentRedirect && (
          <Paragraf cssProps="bold" name="Già registrato/a con Toyota o Lexus? Inserisci le tue credenziali per accedere alla tua area privata. " />
        )}
        <div id="username" className="w-full mt-5">
          <div>
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="email"
              register={register}
              validationOptions={{
                required: 'Campo richiesto',
                validate: {
                  formatErrorEmail,
                },
              }}
              defaultValue=""
              name="email"
              error={errors?.email}
              customLabel="Email"
            />
          </div>
          <div className="mt-4">
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type={type}
              autoComplete="current-password"
              register={register}
              validationOptions={{
                required: 'Campo richiesto',
              }}
              defaultValue=""
              name="password"
              style={{ paddingRight: "45px" }}
              error={errors?.password}
              customLabel="Password"
            />
            <span className="flex justify-around items-center" onClick={handleToggle}>
              <Icon style={{ right: "-6px", marginBottom: errors?.password ? "118px" : "48px", }} className="absolute mr-10" icon={icon} size={25} />
            </span>
          </div>
        </div>
        <div className="mb-6 mt-8 text-center w-full ">
          <CommonButton name="Login" />
        </div>

        <div>
          <br />
          <Link
            to="/password-recovery"
            className={`text-center pl-2 pr-2 underline text-base text-secondary font-${urlisLexus[0]}`}
          >
            Password dimenticata?
          </Link>
        </div>

      </form>
      <div>
        {socialLoginGEnabled &&
          <GoogleSocialLogin
            function={"socialLogin"}
          />
        }
        {socialLoginFbEnabled &&
          <FacebookSocialLogin
            function={"socialLogin"}
          />
        }
        {socialLoginAppleEnabled &&

          <AppleSocialLogin
            function={"socialLogin"}
          />
        }
      </div>
    </div>


  )
}

export default Login
