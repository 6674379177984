import { loadConstants } from 'api/api'
import { loginApi } from 'api/keycloak'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import CryptoJS from 'crypto-js'
import store, {
  appMiscStoreUnsubscribe,
  initStoreSubscription,
  userStoreUnsubscribe,
} from 'store'
import { resetState } from 'store/actions'
import { LocalStorageService } from 'utils/LocalStorageService'

type CustomAxiosInstance = AxiosInstance & {
  setDefaults: (config: Partial<AxiosRequestConfig>) => void
}
interface Config extends AxiosRequestConfig {
  retry: boolean
}

interface ExtendedError extends AxiosError {
  config: Config
}
const statuses = [401]
const responseInterceptor = (response: AxiosResponse) => response
const requestInterceptor = async (config: AxiosRequestConfig) => {
  config.baseURL = (await loadConstants()).BASE_URL
  const token = JSON.parse(sessionStorage.getItem('easyPay'))?.token
    ?.access_token

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  } else {
    delete config.headers.Authorization
  }

  return config
}
const simpleErrorInterceptor = (error: ExtendedError) => {
  return Promise.reject(error)
}

const errorInterceptor = (error: ExtendedError) => {
  const originalRequest = error.config
  const status = error.response?.status || 0

  if (status == 403) {
    endSession()
    return Promise.reject(error)
  }
  if (
    (statuses.includes(status) && originalRequest.url === '/token') ||
    (statuses.includes(status) &&
      !JSON.parse(sessionStorage.getItem('easyPay')))
  ) {
    resetSignIn()
    return Promise.reject(error)
  }

  if (statuses.includes(status) && !originalRequest.retry) {
    originalRequest.retry = true
    const decryptPassphrase = JSON.parse(
      sessionStorage.getItem('user'),
    )?.decryptPassphrase
    if (decryptPassphrase) {
      const token = JSON.parse(sessionStorage.getItem('easyPay'))?.token
      const password = CryptoJS.AES.decrypt(
        token.s3c,
        decryptPassphrase,
      ).toString(CryptoJS.enc.Utf8)
      return loginApi.refreshToken(password).then(res => {
        if (res.status === 200 && res.data) {
          const newToken = res.data
          LocalStorageService.addObject({
            token: { ...token, ...newToken },
          })
          easyPayInstance.defaults.headers.common['Authorization'] =
            'Bearer ' + newToken.access_token
          return easyPayInstance(originalRequest)
        }
      })
    }
  }

  return Promise.reject(error)
}

export const easyPayInstance = axios.create() as CustomAxiosInstance

easyPayInstance.interceptors.request.use(requestInterceptor, errorInterceptor)
easyPayInstance.interceptors.response.use(responseInterceptor, errorInterceptor)

export const keycloakInstance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})
const keycloakRequestInterceptor = async (config: AxiosRequestConfig) => {
  config.baseURL = (await loadConstants()).KEY_CLOACK
  return config
}
keycloakInstance.interceptors.request.use(
  keycloakRequestInterceptor,
  simpleErrorInterceptor,
)

easyPayInstance.setDefaults = config =>
  Object.assign(easyPayInstance.defaults, {
    ...easyPayInstance.defaults,
    ...config,
  })

function endSession() {
  userStoreUnsubscribe()
  appMiscStoreUnsubscribe()

  sessionStorage.clear()

  store.dispatch(resetState())
  initStoreSubscription()

  window.location.href = '/login?session-expired=true'
}
function resetSignIn() {
  userStoreUnsubscribe()
  appMiscStoreUnsubscribe()

  sessionStorage.clear()

  store.dispatch(resetState())
  initStoreSubscription()

  window.location.href = '/login?session-expired=true'
}
