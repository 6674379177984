import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import CommonButton from '../CommonButton'
import Paragraf from '../Paragraf'
import PinPad from '../Pinpad'
import { isLexusCss } from 'utils/utils'

let urlisLexus = []
let url = window.location.href;
isLexusCss(url).then(isLexus => {
  urlisLexus=isLexus
})

type FormPinPadProps = {
  onPinPadSubmit: ({ confirmCode }: { confirmCode: string }) => void
  onResendConfirmationCode: () => void
  onGoBack: () => void
  header: string
  subHeader: string
}
const FormPinPad: React.FC<FormPinPadProps> = ({
  onPinPadSubmit,
  onResendConfirmationCode,
  onGoBack,
  header,
  subHeader,
}) => {
  const goBack = () => onGoBack()
  const pinValidationSchema = Yup.object().shape({
    confirmCode: Yup.string()
      .required('Campo richiesto')
      .min(6, 'Il codice deve essere di 6 cifre'),
  })
  const { control, handleSubmit } = useForm({
    defaultValues: {
      confirmCode: '',
    },
    resolver: yupResolver(pinValidationSchema),
  })

  const handleOnSubmitPinForm = data => {
    onPinPadSubmit(data)
  }

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Paragraf cssProps={"mt-4 text-center font-"+urlisLexus[0]} name={subHeader} />
      <form
        className="mb-4"
        onSubmit={handleSubmit(handleOnSubmitPinForm)}
        noValidate
      >
        <Controller
          control={control}
          name="confirmCode"
          render={({ field }) => <PinPad {...field} />}
        />
      </form>
      <div className="space-y-8">
        <CommonButton
          onClick={() => onResendConfirmationCode()}
          type="button"
          name="Richiedi nuovo codice"
        />
        <CommonButton
          onClick={goBack}
          type="button"
          name="Torna indietro"
          cssType='fill-in-white'
        />
      </div>
    </div>
  )
}

export default FormPinPad
