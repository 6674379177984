export const HOMEPAGE = ''
export const PICTURE_EXPLANATION = 'picture-explanation'
export const RECAP = 'recap'
export const CHOOSE_OPTION = 'choose-option'
export const LOGIN = 'login'
export const REGISTRATION = 'registration'
export const PASSWORD_RECOVERY = 'password-recovery'

export const EMAIL_SUBMISSION = 'email-submission'
export const PHONE_SUBMISSION = 'phone-submission'
export const PERSONAL_INFO_SUBMISSION = 'personal-info-submission'
export const PASSWORD_SUBMISSION = 'password-submission'
export const CUSTOMER_AREA_HOMEPAGE = 'homepage'
export const CUSTOMER_AREA_PROFILE = 'profile'
export const CUSTOMER_AREA_UPDATE_EMAIL = 'update/email'
export const CUSTOMER_AREA_UPDATE_PHONE = 'update/phone'
export const CUSTOMER_AREA_UPDATE_PASSWORD = 'update/password'
export const CUSTOMER_AREA_PAY_INSTALMENT = 'pay-instalment'
export const CUSTOMER_AREA_PAYMENTS_MADE = 'payments-made'
export const CUSTOMER_AREA_PAYMENTS_MADE_DETAIL = 'payments-made/detail'
export const CUSTOMER_AREA_PAYMENTS_SITUATION = 'payments-situation'
export const CUSTOMER_AREA_INFORMATIVE_DOCUMENTS = 'informative-documents'
export const DIRECT_PAYMENT = 'direct-payment'
export const SPLIT_PAYMENT_OVERVIEW = 'split-payment-overview'
export const SPLIT_PAYMENT = 'split-payment'
export const THANK_YOU = 'thank-you'
export const THANK_YOU_FINANCIAL = 'thank-you-financial'
export const ERROR = 'error'
export const KO = 'ko'
export const KO_FINANCIAL = 'ko-financial'
export const PAYMENT_METHOD = 'payment-method'
