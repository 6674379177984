import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

export type RegistrationStepperState = {
  visible: boolean
  steps: 6 | 4
  currentStep: number
}

export const initialState: RegistrationStepperState = {
  visible: false,
  steps: 6,
  currentStep: 1,
}

const registrationStepperSlice = createSlice({
  name: 'registration/stepper',
  initialState,
  reducers: {
    toggleStepVisibility(state, action: PayloadAction<boolean>) {
      state.visible = action.payload
    },
    setStepAmount(state, action: PayloadAction<6 | 4>) {
      state.steps = action.payload
      if (state.currentStep > action.payload) {
        state.currentStep = action.payload
      }
    },
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload
    },
    incrementCurrentStep(state) {
      if (state.currentStep < state.steps) {
        state.currentStep++
      }
    },
    decrementCurrentStep(state) {
      if (state.currentStep > 0) {
        state.currentStep--
      }
    },
    decrementCurrentStepBy(state, action: PayloadAction<number>){
      state.currentStep-=action.payload
    },
    resetStepperState(state) {
      state = { ...initialState }
    },
  },
})

export const selectisStepperVisible = (state: RootState): boolean =>
  state.registrationStepper.visible
export const selectCurrentStep = (state: RootState): number =>
  state.registrationStepper.currentStep
export const selectStepAmount = (state: RootState): number =>
  state.registrationStepper.steps
 

export const {
  toggleStepVisibility,
  setStepAmount,
  setCurrentStep,
  incrementCurrentStep,
  decrementCurrentStep,
  resetStepperState,
  decrementCurrentStepBy
} = registrationStepperSlice.actions
export default registrationStepperSlice.reducer
