import React from 'react'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

type LabelProps = {
  cssProps?: string
  name: string
}

const Label: React.FC<LabelProps> = ({ cssProps, name }) => {
  return (
    <label
      htmlFor={name}
      className={cssProps ?? `font-${urlisLexus[0]} text-primary `}
    >
      {name}
    </label>
  )
}

export default Label
