import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import { loadConstants } from 'api/api'
import Paragraf from 'components/Paragraf'
import { useQuery } from 'Hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LOGIN } from 'routes/constants'
import { RootState } from 'store'
import { logOutRequest } from 'store/slices/userSlice'
import { gapi, loadAuth2 } from 'gapi-script'
import { login } from 'store/slices/userSlice'
import { formatErrorEmail } from 'utils/formValidation'
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode'
import { easyPayInstance } from 'Axios/AxiosInstance'
import { securityApi } from 'api/easypay'
import { printToast } from 'utils/printToast'
import { completeRegistrationProcess } from 'store/slices/appMiscSlice'
import Cookies from 'js-cookie'
import AppleLogin from 'react-apple-login'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { isLexusUrl } from 'utils/utils'

const GoogleSocialLogin = (props) => {
   
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [clientId, setClientId] = useState<string>()
    const [redirectUri, setRedirectUri] = useState<string>()

    useEffect(()=> {
        const getClientId = async () => {
            const temp = (await loadConstants()).GCLOUD_CLIENT_ID
            setClientId(temp)
            console.log('client google'+temp)
        }
    
        getClientId()

        const getRedirectUri = async () => {
            let url = window.location.href;
            if(isLexusUrl(url)){
                const temp = (await loadConstants()).APPLE_REDIRECT_URI_LEXUS
                setRedirectUri(temp)
            } else {
                const temp = (await loadConstants()).APPLE_REDIRECT_URI
                setRedirectUri(temp)
            }
        }
        getRedirectUri()
       
    },[])


    const query = useQuery()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
    })

    const sendLoginForm = async ({
        email,
        password,
        token
      }: {
        email: string
        password: string | null
        token: string
      }) => {
        dispatch(
          login({
            email,
            password,
            navigate,
            urlParameters: query.get('redirectReason'),
            token: {access_token: token}
          }),
        )
      }

    
    useEffect(()=> {
        Cookies.remove("G_AUTHUSER_H")
    }, [])

    function loginForm(token) {
        console.log("Mi sto loggando con google")
        let googleToken = jwt_decode(token)
        securityApi.socialLogin("TOYOTA", token).then((response) => {
            if(response?.data != null) {
                sendLoginForm({email: googleToken.email, password: null, token: response.data.token})
                setIsLoggedIn(true)
            }
        }).catch((e) => {
            console.error(e)
            printToast.error("L'account non è registrato, si prega di procedere con la registrazione.")
        })
    }

    const onSuccess = (res) => {
        if(!isLoggedIn) {
            if(props.function === "socialLogin") {
                loginForm(res.credential)
            }else {
                securityApi.createPassword(
                {
                    userId: props.userId,
                    username: null,
                    password1: null,
                    password2: null,
                    token: res.credential
                }).then((response) => {
                    dispatch(
                        completeRegistrationProcess({
                          navigate
                        })
                    )
                }).catch((e) => {
                    console.error(e)
                    if(e?.response?.data?.messages[0]?.code === 'SOCIAL_ACCOUNT_REGISTERED') {
                        printToast.error(
                            e?.response?.data?.messages[0]?.message,
                          6000,
                        )
                      }else {
                        printToast.error("La registrazione della password non è avvenuta con successo")

                      }
                })
            }
        }
    }
  

    return (
        <div className="mb-2 text-left w-full px-5 pb-5">
            <div>
                <br />
                <div>
                    <GoogleOAuthProvider  clientId={clientId}>
                        <GoogleLogin width={275} onSuccess={onSuccess}/>
                    </GoogleOAuthProvider>    
                    
                </div>
                    
                

            </div>
        </div>
    )
}

export default GoogleSocialLogin;


