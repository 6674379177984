import { AxiosResponse } from 'axios'
import { easyPayInstance } from 'Axios/AxiosInstance'
import { EmailResponse } from 'commons/dto/email'

let appId = "TOYOTA"
let url = window.location.href;

export const getEmail = (
  userId: string,
  email: string,
): Promise<AxiosResponse<EmailResponse>> =>
  easyPayInstance.get(`/${appId}/e-mail/`, {
    params: {
      userId,
      email,
    },
  })

export const insertEmail = (
  userId: string,
  email: string,
  defaultFlag: boolean,
  brand: string,
  confirmed?: boolean,

): Promise<AxiosResponse<EmailResponse>> =>
  easyPayInstance.post(`/${appId}/e-mail/`, {
    userId,
    email,
    defaultFlag,
    brand,
    confirmed,
  })

export const confirmEmail = (
  userId: string,
  confirmCode: string,
  email: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/e-mail/${userId}/confirm`, {
    confirmCode,
    email,
  })

export const updateEmail = (
  previousEmail: string,
  newEmail: string,
  brand: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.put(`/${appId}/e-mail/`, {
    previousEmail,
    newEmail,
    brand,
  })

export const resendOtpEmail = (
  userId: string,
  email: string,
  brand: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/e-mail/${userId}/resend-otp`, {
    email,
    brand,
  })

export const deleteEmail = (email: string): Promise<AxiosResponse<void>> =>
  easyPayInstance.delete(`/${appId}/e-mail/${email}`)
