import Title from 'components/Title'
import React from 'react'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })
type StepperProps = {
  stepsLabels?: string[]
  currentStep?: number
}
const Stepper: React.VFC<StepperProps> = ({ stepsLabels, currentStep }) => {
  const getStepColor = (step: number) => {
    if (step === currentStep) {
      return 'bg-yellow-500'
    } else if (step < currentStep) {
      return 'bg-green-500'
    } else {
      return 'bg-gray-300'
    }
  }

  const renderStepLine = (step: number) => {
    return step > 1 ? (
      <div
        className="absolute flex align-center items-center align-middle content-center bg-gray-200"
        style={{
          width: 'calc(100% - 2.5rem - 1rem)',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          height: '8px',
          borderRadius: '0.25rem',
        }}
      >
        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
          {/* <!-- middle part 100 full & 50 half progressstatus--> */}
        </div>
      </div>
    ) : null
  }

  return (
    <section>
      <Title
        name="Fase attuale"
        secondline={`Attualmente sei nella fase di: ${
          stepsLabels[currentStep - 1]
        } `}
      />
      {/* <!-- component --> */}
      <div className="container mx-auto py-6">
        <div
          className="flex flex-nowrap overflow-x-auto pb-10 hide-scroll-bar"
          id="ProgressStepper"
        >
          {stepsLabels &&
            stepsLabels.map((label, index) => (
              <div className="w-full" key={label}>
                <div className="relative mb-2">
                  {renderStepLine(index + 1)}
                  <div
                    className={`${getStepColor(
                      index + 1,
                    )} w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center `}
                  >
                    <span className="text-center text-white w-full">
                      {index + 1}
                    </span>
                  </div>
                </div>
                <div className={`text-xs text-center 2xl:text-base font-${urlisLexus[0]} `}>
                  {label}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Stepper
