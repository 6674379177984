import React from 'react'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })


type Props = {
  cssProps?: string
  name?: string
  title?: string
  detail?: string
}

const style: any = {
  ':empty': { display: 'none' },
}
const ParagrafTailwind: React.FC<Props> = ({
  cssProps,
  name,
  title,
  detail,
}) => {
  return (
    <>
      <h3 style={style} className={"remove-empty-content font-"+urlisLexus[0]}>
        {title}
      </h3>

      <p
        style={style}
        className={cssProps && cssProps.includes("bold") && ((urlisLexus[0] && urlisLexus[0].includes("Toyota")) || !urlisLexus[0]) ? `remove-empty-content pb-4 font-ToyotaTypeBold` : cssProps && cssProps.includes("bold") && urlisLexus[0] && urlisLexus[0].includes("Lexus") ? `remove-empty-content pb-4 font-LexusTypeBold` : cssProps ?? `remove-empty-content pb-4 font-${urlisLexus[0]}`}
      >
        {name}
        {detail && (
          <>
            <br /> {detail}
          </>
        )}
      </p>
    </>
  )
}

export default ParagrafTailwind
