interface LoadJsonResponse {
  BASE_URL: string
  CLIENT_ID: string
  CLIENT_SECRET: string
  GRANT_TYPE: string
  KEY_CLOACK: string
  GCLOUD_CLIENT_ID: string
  FACEBOOK_APP_ID: string
  APPLE_CLIENT_ID: string
  APPLE_CLIENT_ID_LEXUS: string
  APPLE_REDIRECT_URI: string
  APPLE_REDIRECT_URI_LEXUS: string
  SOCIAL_LOGIN_GOOGLE_ENABLED: string
  SOCIAL_LOGIN_FACEBOOK_ENABLED: string
  SOCIAL_LOGIN_APPLE_ENABLED: string
  LEXUS_DOMAIN: string
  URL_LEXUS: string
  TOYOTA_DOMAIN: string
  BRAND_TOYOTA: string
  BRAND_LEXUS: string
  APP_ID: string
}

function getPromisifiedFetch(url) {
  return new Promise(function (resolve, reject) {
    fetch(url)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(error => reject(error))
  })
}

export const loadConstants = async (): Promise<LoadJsonResponse> => {
  const publicApi = (await getPromisifiedFetch(
    `${process.env.PUBLIC_URL}/api/api.json`,
  )) as unknown as LoadJsonResponse

  const BASE_URL = publicApi.BASE_URL
  const CLIENT_ID = publicApi.CLIENT_ID
  const CLIENT_SECRET = publicApi.CLIENT_SECRET
  const GRANT_TYPE = publicApi.GRANT_TYPE
  const KEY_CLOACK = publicApi.KEY_CLOACK
  const GCLOUD_CLIENT_ID = publicApi.GCLOUD_CLIENT_ID
  const FACEBOOK_APP_ID = publicApi.FACEBOOK_APP_ID
  const APPLE_CLIENT_ID = publicApi.APPLE_CLIENT_ID
  const APPLE_CLIENT_ID_LEXUS = publicApi.APPLE_CLIENT_ID_LEXUS
  const APPLE_REDIRECT_URI = publicApi.APPLE_REDIRECT_URI
  const APPLE_REDIRECT_URI_LEXUS = publicApi.APPLE_REDIRECT_URI_LEXUS
  const SOCIAL_LOGIN_GOOGLE_ENABLED = publicApi.SOCIAL_LOGIN_GOOGLE_ENABLED
  const SOCIAL_LOGIN_FACEBOOK_ENABLED = publicApi.SOCIAL_LOGIN_FACEBOOK_ENABLED
  const SOCIAL_LOGIN_APPLE_ENABLED = publicApi.SOCIAL_LOGIN_APPLE_ENABLED
  const LEXUS_DOMAIN = publicApi.LEXUS_DOMAIN
  const TOYOTA_DOMAIN = publicApi.TOYOTA_DOMAIN
  const APP_ID = publicApi.APP_ID
  const BRAND_TOYOTA = publicApi.BRAND_TOYOTA
  const BRAND_LEXUS = publicApi.BRAND_LEXUS
  const URL_LEXUS = publicApi.URL_LEXUS

  return {
    CLIENT_ID, CLIENT_SECRET, GRANT_TYPE, BASE_URL, KEY_CLOACK, GCLOUD_CLIENT_ID, FACEBOOK_APP_ID,
    SOCIAL_LOGIN_GOOGLE_ENABLED, SOCIAL_LOGIN_FACEBOOK_ENABLED, SOCIAL_LOGIN_APPLE_ENABLED,
    APPLE_CLIENT_ID,APPLE_CLIENT_ID_LEXUS, APPLE_REDIRECT_URI,APPLE_REDIRECT_URI_LEXUS, LEXUS_DOMAIN, TOYOTA_DOMAIN, APP_ID, BRAND_TOYOTA, BRAND_LEXUS, URL_LEXUS
  }
}
