import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import { loadConstants } from 'api/api'
import Paragraf from 'components/Paragraf'
import { useQuery } from 'Hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LOGIN } from 'routes/constants'
import { RootState } from 'store'
import { logOutRequest } from 'store/slices/userSlice'
import { gapi } from 'gapi-script'
import { login } from 'store/slices/userSlice'
import { formatErrorEmail } from 'utils/formValidation'
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode'
import { easyPayInstance } from 'Axios/AxiosInstance'
import { securityApi } from 'api/easypay'
import { printToast } from 'utils/printToast'
import { completeRegistrationProcess } from 'store/slices/appMiscSlice'
import Cookies from 'js-cookie'
import AppleLogin from 'react-apple-login'
import AppleSignin from 'react-apple-signin-auth';
import { isLexusUrl, isLexusUrlStatic } from 'utils/utils'
const AppleSocialLogin = (props) => {
   
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [clientId, setClientId] = useState<string>()
    const [appleRedirectUri, setAppleRedirectUri] = useState<string>()

    useEffect(()=> {
        const getClientId = async () => {
            let url = window.location.href;
            if(isLexusUrlStatic(url)){
                const temp = (await loadConstants()).APPLE_CLIENT_ID_LEXUS
                setClientId(temp)
            } else {
                const temp = (await loadConstants()).APPLE_CLIENT_ID
                setClientId(temp)
            }
        }
        getClientId()
        const getRedirectUri = async () => {
            let url = window.location.href;
            let redirect;
            if(isLexusUrlStatic(url)){
                redirect = (await loadConstants()).APPLE_REDIRECT_URI_LEXUS
            }else{
                redirect = (await loadConstants()).APPLE_REDIRECT_URI
            }
            setAppleRedirectUri(redirect)
        }
        getRedirectUri()
    },[])

    const query = useQuery()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
    })

    const sendLoginForm = async ({
        email,
        password,
        token
      }: {
        email: string
        password: string | null
        token: string
      }) => {
        dispatch(
          login({
            email,
            password,
            navigate,
            urlParameters: query.get('redirectReason'),
            token: {access_token: token}
          }),
        )
      }



    function loginForm(token) {
        securityApi.socialLogin("TOYOTA", token).then((response) => {
            if(response?.data != null) {
                let keycloakJwtToken = jwt_decode(response.data.token);
                sendLoginForm({email: keycloakJwtToken.email, password: null, token: response.data.token})
                setIsLoggedIn(true)
            }
        }).catch((e) => {
            console.error(e)
            printToast.error("L'account non è registrato, si prega di procedere con la registrazione.")
        })
    }

    const responseApple = (response) => {
        console.log("callback apple chiamata")
        if(!isLoggedIn) {
            if(props.function === "socialLogin") {
                loginForm(response.authorization.id_token)
            }else {
                console.log("createPassword")
                securityApi.createPassword(
                {
                    userId: props.userId,
                    username: null,
                    password1: null,
                    password2: null,
                    token: response.authorization.id_token
                }).then((response) => {
                    dispatch(
                        completeRegistrationProcess({
                          navigate
                        })
                    )
                }).catch((e) => {
                    console.error(e)
                    if (e?.response?.data?.messages[0]?.code === 'SOCIAL_ACCOUNT_REGISTERED') {
                        printToast.error(
                            e?.response?.data?.messages[0]?.message,
                          6000,
                        )
                      }else {
                        printToast.error("La registrazione della password non è avvenuta con successo")

                      }
                })
            }
        }
    }

    return (
        <div className="mb-2 text-left w-full px-5 pb-5">
            <div>
                {clientId &&
                    <AppleSignin
                        authOptions={{
                            clientId: clientId,
                            usePopup: true,
                            scope: 'email',
                            redirectURI: appleRedirectUri,
                        }}
                        uiType="dark"
                        className="apple-auth-btn"
                        noDefaultStyle={false}
                        buttonExtraChildren="Continue with Apple"
                        onSuccess={responseApple}
                        onError={(error) => console.error(error)}
                    /> 
                }               
            </div>
               
        </div>
    )
}

export default AppleSocialLogin;


