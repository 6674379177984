import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from 'store/slices/userSlice'
import { Navigate } from 'react-router-dom'
import { LOGIN } from 'routes/constants'

const RoleGuard: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  return <>{isLoggedIn ? children : <Navigate to={`/${LOGIN} `} />}</>
}

export default RoleGuard
