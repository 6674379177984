import { AxiosResponse } from 'axios'
import { easyPayInstance } from 'Axios/AxiosInstance'
import { getActiveWorkOrdersResponse } from 'commons/workOrders'
import { WorkOrder, WorkOrderDownload } from 'store/types/workOrder'

let appId = "TOYOTA"
let url = window.location.href;

export const downloadWorkOrder = ({
  workOrderId,
}: {
  workOrderId: string
}): Promise<AxiosResponse<WorkOrderDownload>> => {
  return easyPayInstance.get(`/${appId}/download/workOrder?workOrderId=${workOrderId}`)
}

export const getWorkOrderById = ({
  workOrderId,
}: {
  workOrderId: string
}): Promise<AxiosResponse<WorkOrder>> => {
  return easyPayInstance.get(`/${appId}/workOrders/`, {
    params: {
      workOrderId,
    },
  })
}

export const getActiveWorkOrders = ({
  from,
  to,
}: {
  from?: string
  to?: string
}): Promise<AxiosResponse<Array<getActiveWorkOrdersResponse>>> => {
  return easyPayInstance.get(`/${appId}/customer/workOrders/`, {
    params: {
      from,
      to,
    },
  })
}
