import React from 'react'

type GridLayoutProps = {
  mobileUrl: string
  desktopUrl: string
  cssProps?: string
}

const GridLayout: React.FC<GridLayoutProps> = ({
  children,
  mobileUrl,
  desktopUrl,
  cssProps,
}) => {
  return (
    <div
      className={`w-full md:max-w-7xl mx-auto grid md:grid-cols-2 md:space-x-20 place-items-start ${cssProps} `}
    >
      <div className="mx-auto md:mx-0 row-start-3 row-end-4 md:row-auto w-4/5">
        {children}
      </div>
      <img
        className="md:row-auto hidden md:block"
        src={desktopUrl}
        alt="Toyota easy pay"
      />
      <img
        className="row-start-1 row-end-2 md:hidden mx-auto"
        src={mobileUrl}
        alt="Toyota easy pay"
      />
    </div>
  )
}

export default GridLayout
