import React from 'react'
import loaderToyota from '../images/toyota.loader.gif'
import loaderLexus from '../images/loader-lexus.gif'
import { isLexusUrlStatic } from 'utils/utils'

const LoadingButton: React.VFC = () => {
  let loader = loaderToyota
  let url = window.location.href;
  let loaderStyle:{margin?:string, width?:number, height?:string} = { margin: '10px auto', width: 50 };
  if(isLexusUrlStatic(url)){
    loader = loaderLexus
    loaderStyle = { margin: '10px auto', height:'7rem' };
  }
  return (
    <img src={loader} style={loaderStyle} alt="Loader" />
  )
}

export default LoadingButton
