import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCurrentStep,
  selectisStepperVisible,
  selectStepAmount,
} from 'store/slices/registrationStepper'
import { isLexusUrl } from 'utils/utils'

const RegistrationStepper: React.FC = ({ children }) => {
  const isStepperVisible = useSelector(selectisStepperVisible)
  const currentStep = useSelector(selectCurrentStep)
  const totalSteps = useSelector(selectStepAmount)

  const [checkRefreshIsStepperVisibile, setCheckRefreshIsStepperVisibile] = useState(false)
  const [currentStepRefresh, setCurrentStepRefresh] = useState(1)

  useEffect(() => {
    let link = window.location.href;
    switch (true) {
      case link.includes("phone-submission"):
        setCurrentStepRefresh(totalSteps == 6 ? 3 : 1);
        setCheckRefreshIsStepperVisibile(true);
        break;
      case link.includes("email-submission"):
        setCurrentStepRefresh(1);
        setCheckRefreshIsStepperVisibile(true);
        break;
      case link.includes("personal-info-submission"):
        setCurrentStepRefresh(totalSteps == 6 ? 5 : 3);
        setCheckRefreshIsStepperVisibile(true);
        break;
      case link.includes("password-submission"):
        setCurrentStepRefresh(totalSteps == 6 ? 6 : 4);
        setCheckRefreshIsStepperVisibile(true);
        break;
      default:
        setCheckRefreshIsStepperVisibile(false);
        break;
    }
  });

  const [backgroundColorStepper, setBackgroundColorStepper] = useState("")
  useEffect(() => {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
      if (isLexus) {
        setBackgroundColorStepper("bg-lexusBlue")
      } else {
        setBackgroundColorStepper("bg-primary")
      }
    })
  }, [])


  return (
    <>
      {(isStepperVisible) && (
        <ul
          className="flex gap-1 flex-wrap px-5"
          aria-label="step di registrazione"
        >
          {new Array(totalSteps).fill(undefined).map((_, idx) => (
            <li
              key={idx}
              className={`flex-grow h-1 my-3 ${currentStep - 1 >= idx ? backgroundColorStepper : 'bg-gray-400'
                } `}
              aria-current={currentStep - 1 === idx ? 'step' : 'false'}
            >
              <span className="sr-only">
                step {currentStep} di {totalSteps}
              </span>
            </li>
          ))}
        </ul>
      )}
      {(!isStepperVisible && checkRefreshIsStepperVisibile) && (
        <ul
          className="flex gap-1 flex-wrap px-5"
          aria-label="step di registrazione"
        >
          {new Array(totalSteps).fill(undefined).map((_, idx) => (
            <li
              key={idx}
              className={`flex-grow h-1 my-3 ${currentStepRefresh - 1 >= idx ? backgroundColorStepper : 'bg-gray-400'
                } `}
              aria-current={currentStepRefresh - 1 === idx ? 'step' : 'false'}
            >
              <span className="sr-only">
                step {currentStepRefresh} di {totalSteps}
              </span>
            </li>
          ))}
        </ul>
      )}
      {children}
    </>
  )
}

export default RegistrationStepper
