import { AxiosResponse } from 'axios'
import { AddressResponse } from 'commons/dto/address'
import { easyPayInstance } from 'Axios/AxiosInstance'

let appId = "TOYOTA";

export const getAddress = (
  entityId: string,
  address?: string,
  addressId?: string,
): Promise<AxiosResponse<AddressResponse>> =>
  easyPayInstance.get(`/${appId}/address/`, { params: { entityId, address, addressId } })

export const insertAddress = ({
  entityId,
  defaultFlag,
  city,
  country,
  postalCode,
  streetAddress,
  streetNumber,
}): Promise<AxiosResponse<void>> =>
  easyPayInstance.post(`/${appId}/address/`, {
    entityId,
    defaultFlag,
    address: {
      city,
      country,
      gps: {
        altitude: 0,
        direction: 0,
        latitude: 0,
        longitude: 0,
      },
      postalCode,
      streetAddress,
      streetNumber,
    },
  })

export const updateAddress = (
  addressId: string,
  city: string,
  country: string,
  postalCode: string,
  streetAddress: string,
  streetNumber: string,
): Promise<AxiosResponse<AddressResponse>> =>
  easyPayInstance.patch(`/${appId}/address/${addressId}`, {
    city,
    country,
    postalCode,
    streetAddress,
    streetNumber,
    gps: {
      altitude: 0,
      direction: 0,
      latitude: 0,
      longitude: 0,
    },
  })
