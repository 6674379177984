import { yupResolver } from '@hookform/resolvers/yup'
import { securityApi } from 'api/easypay'
import BottomModal from 'components/BottomModal'
import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import Paragraf from 'components/Paragraf'
import Title from 'components/Title'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsCheckAll } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  completeRegistrationProcess,
  selectWorkOrder,
  toggleLoading,
} from 'store/slices/appMiscSlice'
import { decrementCurrentStep } from 'store/slices/registrationStepper'
import { selectTempUser, selectUserEmail } from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'
import * as Yup from 'yup'
import GoogleSocialLogin from 'containers/Login/GoogleSocialLogin'
import FacebookSocialLogin from 'containers/Login/FacebookSocialLogin'
import { loadConstants } from 'api/api'
import AppleSocialLogin from 'containers/Login/AppleSocialLogin'
import { classNames } from 'utils/css'
import { isLexusUrl } from 'utils/utils'

const passwordRegex =
  /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/

const PasswordSubmission: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { id: tempUserId } = useSelector(selectTempUser)
  const email = useSelector(selectUserEmail)
  const emailFallbackFromWorkOrder = useSelector(selectWorkOrder).email

  const [isAtLeastEightCharsValid, setisAtLeastEightCharsValid] =
    useState(false)
  const [
    isAtLeastOneUpperCaseLetterValid,
    setisAtLeastOneUpperCaseLetterValid,
  ] = useState(false)
  const [isAtLeastOneNumberValid, setisAtLeastOneNumberValid] = useState(false)
  const [
    isAtLeastOneSpecialCharacterValid,
    setisAtLeastOneSpecialCharacterValid,
  ] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const passwordRef = useRef<string | null>(null)
  const confirmPasswordRef = useRef<string | null>(null)
  const [socialLoginFbEnabled, setSocialLoginFbEnabled] = useState<string>()
  const [socialLoginGEnabled, setSocialLoginGEnabled] = useState<string>()
  const [socialLoginAppleEnabled, setSocialLoginAppleEnabled] = useState<string>()

  const handleOnDismissModal = () => setIsModalOpen(false)



  

  const [backgroundAlertColor, setBackgroundAlertColor] = useState("")
  useEffect(() => {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
    if(isLexus) {
      setBackgroundAlertColor("bg-lexusBlue")
    }else{
      setBackgroundAlertColor("bg-primary")
    }})
  },[])

  useEffect(()=>{
    const getSocialLoginGEnabled = async () => {
      const temp = (await loadConstants()).SOCIAL_LOGIN_GOOGLE_ENABLED
      setSocialLoginGEnabled(temp)
    }

    const getSocialLoginFbEnabled = async () => {
      const temp = (await loadConstants()).SOCIAL_LOGIN_FACEBOOK_ENABLED
      setSocialLoginFbEnabled(temp)
    }

    const getSocialLoginAppleEnabled = async () => {
      const temp = (await loadConstants()).SOCIAL_LOGIN_APPLE_ENABLED
      setSocialLoginAppleEnabled(temp)
    }
    getSocialLoginGEnabled() 
    getSocialLoginFbEnabled() 
    getSocialLoginAppleEnabled()

  },[])
  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(
        passwordRegex,
        `La password deve contenere almeno 8 caratteri, una lettera
      maiuscola, una minuscola, un numero e un simbolo speciale
      (! @ # $ % ^ & *)'`,
      )
      .required('Campo richiesto'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'La password deve corrispondere')
      .required('Campo richiesto'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  })

  const onSubmit = async ({ password, confirmPassword }) => {
    passwordRef.current = password
    confirmPasswordRef.current = confirmPassword
    setIsModalOpen(true)
  }

  const handleOnAcceptedToContinueModal = async () => {
    dispatch(toggleLoading({ value: true }))
    try {
      await securityApi.createPassword({
        userId: tempUserId,
        username: email || emailFallbackFromWorkOrder,
        password1: passwordRef.current,
        password2: confirmPasswordRef.current,
        token: null
      })
      await dispatch(
        completeRegistrationProcess({
          navigate,
        }),
      )
    } catch (e) {
      console.error(e)
      printToast.error(
        "E' avvenuto un errore nella creazione dell'account. Riprovare più tardi.",
      )
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const handleOnBlur = inputName => {
    trigger(inputName)
  }

  const handleOnPasswordChange = e => {
    const { value } = e.target
    setisAtLeastEightCharsValid(/(?=.{8,})/.test(value))
    setisAtLeastOneUpperCaseLetterValid(/(?=.*[A-Z])/.test(value))
    setisAtLeastOneNumberValid(/(?=.*[0-9])/.test(value))
    setisAtLeastOneSpecialCharacterValid(/(?=.*[$%!?@])/.test(value))
    setValue('password', value)
  }

  const goBack = () => {
    dispatch(decrementCurrentStep())
    navigate(-1)
  }

  const passwordValidationTexts = [
    {
      isValid: isAtLeastEightCharsValid,
      text: 'Almeno 8 caratteri',
    },
    {
      isValid: isAtLeastOneUpperCaseLetterValid,
      text: 'Almeno una lettera maiuscola',
    },
    {
      isValid: isAtLeastOneNumberValid,
      text: 'Almeno un numero',
    },
    {
      isValid: isAtLeastOneSpecialCharacterValid,
      text: 'Almeno un simbolo speciale',
    },
  ]

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Paragraf
        cssProps="my-6 px-2 text-left"
        name="La password deve contenere"
      />

      {passwordValidationTexts.map((item, index) => (
        <div key={index} className="flex space-x-2 items-center">
          {item.isValid && <BsCheckAll size="1.5em" color="#17B169" />}
          <Paragraf
            cssProps={`text-gray-500 flex-1 my-4 px-2 text-left ${
              item.isValid && 'line-through text-gray-300 font-light'
            }  `}
            name={item.text}
          />
        </div>
      ))}

      <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full pl-2 pr-2 mt-5 space-y-8">
          <div>
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="password"
              autoComplete="new-password"
              register={register}
              name="password"
              onBlur={() => handleOnBlur('password')}
              onChange={handleOnPasswordChange}
              error={errors?.password}
              customLabel="Password"
            />
          </div>
          <div className="mt-4">
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="password"
              autoComplete="new-password"
              register={register}
              name="confirmPassword"
              onBlur={() => handleOnBlur('confirmPassword')}
              error={errors?.confirmPassword}
              customLabel="Conferma Password"
            />
          </div>
        </div>
        <div className="mb-2 mt-16 text-center w-full space-y-8">
          <CommonButton type="submit" name="Continua" disabled={!isValid} />
          <CommonButton
            onClick={goBack}
            type="button"
            name="Torna indietro"
            cssType='fill-in-white'
          />
        </div>
      </form>
      <BottomModal isOpen={isModalOpen} onDismiss={handleOnDismissModal}>
        <div className="text-center space-y-5 py-3">
          <Title name="Attenzione!" />
          <div
            className={classNames("flex items-center text-white text-sm font-bold px-4 py-3", backgroundAlertColor)}
            role="alert"
          >
            <p>
              Il metodo di pagamento potrebbe richiederti un&apos;autorizzazione
              di €0: non chiudere la pagina.
            </p>
          </div>
          <CommonButton
            name="Continua"
            onClick={handleOnAcceptedToContinueModal}
          />
        </div>
      </BottomModal>
      <div>


          {socialLoginGEnabled &&
            <GoogleSocialLogin
              function={"socialRegistration"}
              userId={tempUserId}
            />
          }
          {socialLoginFbEnabled &&
            <FacebookSocialLogin
              function={"socialRegistration"}
              userId={tempUserId}
            />
          }
          {socialLoginAppleEnabled &&
            <AppleSocialLogin 
              function={"socialRegistration"}
              userId={tempUserId}

            />
          }
      </div>
    </div>
  )
}

export default PasswordSubmission
