import React from 'react'
import 'react-spring-bottom-sheet/dist/style.css'
import { BottomSheet } from 'react-spring-bottom-sheet'

type BottomModalProps = {
  isOpen: boolean
  onDismiss: () => void
  onSpringEnd?: () => void
  onSpringStart?: () => void
}
const BottomModal: React.FC<BottomModalProps> = ({
  children,
  isOpen,
  onDismiss,
  onSpringEnd,
  onSpringStart,
}) => {
  return (
    <BottomSheet
      onDismiss={onDismiss}
      open={isOpen}
      onSpringStart={onSpringStart}
      onSpringEnd={onSpringEnd}
    >
      {children}
    </BottomSheet>
  )
}

export default BottomModal
