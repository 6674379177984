import { securityApi } from 'api/easypay'
import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import Paragraf from 'components/Paragraf'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { formatErrorEmail } from 'utils/formValidation'
import { printToast } from 'utils/printToast'

const passwordSent =
    'Se l\' indirizzo è censito a sistema le verrà inviata una mail per il recupero password. Segui le indicazioni riportate nella e-mail.'

const PasswordRecovery: React.FC = () => {
  const [loadingApi, setLoadingApi] = useState(false)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  })

  const sendLoginForm = async ({ email }: { email: string }) => {
    setLoadingApi(true)
    try {
      await securityApi.recoverPassword(email)
        
    } catch (e) {
        console.error(e)
    } finally {
        printToast.success(passwordSent, 5000, () => navigate(-1))
      setLoadingApi(false)
    }
  }

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <form onSubmit={handleSubmit(sendLoginForm)} noValidate>
        <Paragraf name="Ti verrà inviato un link per il ripristino della password" />
        <div id="username" className="w-full pl-2 pr-2 mt-5">
          <div>
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="email"
              register={register}
              validationOptions={{
                required: 'Campo richiesto',
                validate: {
                  formatErrorEmail,
                },
              }}
              defaultValue=""
              name="email"
              error={errors?.email}
              customLabel="Email"
            />
          </div>
        </div>
        <div className="mb-2 mt-8 text-center w-full ">
          <CommonButton
            disabled={loadingApi}
            name="Invia"
            loadingApi={loadingApi}
          />
        </div>
      </form>
    </div>
  )
}

export default PasswordRecovery
