import { yupResolver } from '@hookform/resolvers/yup'
import { securityApi } from 'api/easypay'
import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import Paragraf from 'components/Paragraf'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsCheckAll } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggleLoading } from 'store/slices/appMiscSlice'
import { selectUserInfo } from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'
import * as Yup from 'yup'
import { isLexusCss} from 'utils/utils'

let urlisLexus = []
let url = window.location.href;
  isLexusCss(url).then(isLexus => {
     urlisLexus=isLexus
  })

const passwordRegex =
  /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/

const UpdatePassword: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userId, email } = useSelector(selectUserInfo)
  const [isAtLeastEightCharsValid, setisAtLeastEightCharsValid] =
    useState(false)
  const [
    isAtLeastOneUpperCaseLetterValid,
    setisAtLeastOneUpperCaseLetterValid,
  ] = useState(false)
  const [isAtLeastOneNumberValid, setisAtLeastOneNumberValid] = useState(false)
  const [
    isAtLeastOneSpecialCharacterValid,
    setisAtLeastOneSpecialCharacterValid,
  ] = useState(false)

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required('Campo richiesto'),
    password: Yup.string()
      .matches(
        passwordRegex,
        `La password deve contenere almeno 8 caratteri, una lettera
      maiuscola, una minuscola, un numero e un simbolo speciale
      (! @ # $ % ^ & *)'`,
      )
      .required('Campo richiesto'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'La password deve corrispondere')
      .required('Campo richiesto'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  })

  const onSubmit = async ({ currentPassword, password, confirmPassword }) => {
    try {
      dispatch(toggleLoading({ value: true }))
      await securityApi.updatePassword({
        userId,
        username: email,
        oldPassword: currentPassword,
        password1: password,
        password2: confirmPassword,
      })
      navigate('/customer-area/homepage'),
        printToast.success('Password aggiornata con successo!')
    } catch (e) {
      console.error(e)
      printToast.error(undefined, undefined)
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const handleOnBlur = inputName => {
    trigger(inputName)
  }

  const handleOnPasswordChange = e => {
    const { value } = e.target
    setisAtLeastEightCharsValid(/(?=.{8,})/.test(value))
    setisAtLeastOneUpperCaseLetterValid(/(?=.*[A-Z])/.test(value))
    setisAtLeastOneNumberValid(/(?=.*[0-9])/.test(value))
    setisAtLeastOneSpecialCharacterValid(/(?=.*[$%!?@])/.test(value))
    setValue('password', value)
  }

  const passwordValidationTexts = [
    {
      isValid: isAtLeastEightCharsValid,
      text: 'Almeno 8 caratteri',
    },
    {
      isValid: isAtLeastOneUpperCaseLetterValid,
      text: 'Almeno una lettera maiuscola',
    },
    {
      isValid: isAtLeastOneNumberValid,
      text: 'Almeno un numero',
    },
    {
      isValid: isAtLeastOneSpecialCharacterValid,
      text: 'Almeno un simbolo speciale',
    },
  ]

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Paragraf
        cssProps={`my-3 px-2 text-left font-${urlisLexus[0]}`}
        name="Inserisci la tua password"
      />
      <Paragraf
        cssProps={`my-6 px-2 text-left font-${urlisLexus[0]}`}
        name="La password deve contenere"
      />

      {passwordValidationTexts.map((item, index) => (
        <div key={index} className="flex space-x-2 items-center">
          {item.isValid && <BsCheckAll size="1.5em" color="#17B169" />}
          <Paragraf
            cssProps={`text-gray-500 flex-1 my-4 px-2 text-left font-${urlisLexus[0]} ${
              item.isValid && 'line-through text-gray-300 font-light'
            }  `}
            name={item.text}
          />
        </div>
      ))}

      <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full pl-2 pr-2 mt-5 space-y-8">
          <div>
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="password"
              autoComplete="current-password"
              register={register}
              name="currentPassword"
              onBlur={() => handleOnBlur('currentPassword')}
              error={errors?.currentPassword}
              customLabel="Password Attuale"
            />
          </div>
          <div>
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="password"
              autoComplete="new-password"
              register={register}
              name="password"
              onBlur={() => handleOnBlur('password')}
              onChange={handleOnPasswordChange}
              error={errors?.password}
              customLabel="Nuova password"
            />
          </div>
          <div className="mt-4">
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="password"
              autoComplete="new-password"
              register={register}
              name="confirmPassword"
              onBlur={() => handleOnBlur('confirmPassword')}
              error={errors?.confirmPassword}
              customLabel="Conferma nuova Password"
            />
          </div>
        </div>
        <div className="mt-8">
          <CommonButton type="submit" name="Continua" disabled={!isValid} />
          <CommonButton
            type="button"
            name="Indietro"
            cssType='fill-in-white'
            onClick={() => {
              navigate('/customer-area/homepage')
            }}
          />
        </div>
      </form>
    </div>
  )
}

export default UpdatePassword
