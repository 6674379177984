import React from 'react'
import { ToastContainer, ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Toast: React.FC<ToastOptions> = props => (
  <ToastContainer
    position="top-right"
    autoClose={60000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    onClick={() => {}}
    rtl={false}
    pauseOnFocusLoss
    draggable
    limit={1}
    {...props}
  />
)

export default Toast
