import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import { loadConstants } from 'api/api'
import Paragraf from 'components/Paragraf'
import { useQuery } from 'Hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LOGIN } from 'routes/constants'
import { RootState } from 'store'
import { logOutRequest } from 'store/slices/userSlice'
import { gapi } from 'gapi-script'
import { login } from 'store/slices/userSlice'
import { formatErrorEmail } from 'utils/formValidation'
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { useCookies } from 'react-cookie';
import { easyPayInstance } from 'Axios/AxiosInstance'
import { securityApi } from 'api/easypay'
import { printToast } from 'utils/printToast'
import { completeRegistrationProcess } from 'store/slices/appMiscSlice'
import Cookies from 'js-cookie'
import { IoLogoDesignernews } from 'react-icons/io5'
import FacebookLogin from '@greatsumini/react-facebook-login';

const FacebookSocialLogin = (props) => {
   
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [facebookAppId, setFacebookAppId] = useState<string>()

    useEffect(()=> {
        const getFacebookAppId = async () => {
            const temp = (await loadConstants()).FACEBOOK_APP_ID
            setFacebookAppId(temp)
        }
        getFacebookAppId()
    },[])

    const query = useQuery()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
    })

    const sendLoginForm = async ({
        email,
        password,
        token
      }: {
        email: string
        password: string | null
        token: string
      }) => {
        dispatch(
          login({
            email,
            password,
            navigate,
            urlParameters: query.get('redirectReason'),
            token: {access_token: token}
          }),
        )
      }

    

    function loginForm(token) {
        console.log("Mi sto loggando con facebook")
        securityApi.socialLogin("TOYOTA", token).then((response) => {
            if(response?.data != null) {
                sendLoginForm({email: token.email, password: null, token: response.data.token})
                setIsLoggedIn(true)
            }
        }).catch((e) => {
            console.error(e)
            printToast.error("L'account non è registrato, si prega di procedere con la registrazione.")
        })
    }

    const responseFacebook = (response) => {
        if(!isLoggedIn) {
            if(props.function === "socialLogin") {
                loginForm(response.accessToken)
            }else {

                securityApi.createPassword(
                {
                    userId: props.userId,
                    username: null,
                    password1: null,
                    password2: null,
                    token: response.accessToken
                }).then((response) => {
                    dispatch(
                        completeRegistrationProcess({
                          navigate
                        })
                    )
                }).catch((e) => {
                    console.error(e)
                    if(e?.response?.data?.messages[0]?.code === 'SOCIAL_ACCOUNT_REGISTERED') {
                        printToast.error(
                            e?.response?.data?.messages[0]?.message,
                          6000,
                        )
                      }else {
                        printToast.error("La registrazione della password non è avvenuta con successo")

                      }
                })
            }
        }
    }
    return (
        <div className="mb-2 text-left w-full px-5 pb-5">
            <div>
                {facebookAppId &&
               
                  <FacebookLogin
                  style={{
                    backgroundColor: '#4267b2',
                    color: '#fff',
                    fontSize: '16px',
                    padding: '12px 24px',
                    border: 'none',
                    borderRadius: '4px',
                    width:'275px'
                  }}
                  appId={facebookAppId}
                  scope="public_profile"
                  initParams={{
                    version: 'v18.0',
                    xfbml: true,
                  }}
                   
                    onSuccess={(response) => {
                        console.log('Login Success!', response);
                        responseFacebook(response)
                    }}
                    onFail={(error) => {
                        console.log('Login Failed!', error);
                    }}
                    onProfileSuccess={(response) => {
                        console.log('Get Profile Success!', response);
                    }}
                    
                    />
                }
            </div>
        </div>
    )
}

export default FacebookSocialLogin;


