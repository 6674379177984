const numbersUtil = {
  format: function (number) {
    if (number) {
      let formatter = new Intl.NumberFormat('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      let num = Number(number).toFixed(2)

      return formatter.format(num)
    }
    return null
  },
}

export default numbersUtil
