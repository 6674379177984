import { CommonButton, Paragraf } from 'components'
import BottomModal from 'components/BottomModal'
import Card from 'components/Card'
import GoBackButton from 'components/GoBackButton'
import Title from 'components/Title'
import GridLayout from 'layout/GridLayout'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  requestPayInstalment,
  requestUserActiveWorkOrders,
  requestUserCards,
} from 'store/actions'
import {
  selectActiveUserCards,
  selectActiveWorkOrders,
  selectUserInfo,
} from 'store/slices/userSlice'
import { classNames } from 'utils/css'
import { isLexusCss,isLexusUrl } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })
 

const PaymentsSituationDetail: React.VFC = () => {
  const { workOrderId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cards = useSelector(selectActiveUserCards)
  const { name, surname } = useSelector(selectUserInfo)
  const workOrders = useSelector(selectActiveWorkOrders)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalPayment, setModalPayment] = useState<boolean>(false)

  const [selectedWorkOrder, setSelectedWorkOrder] =
    useState<typeof workOrders[0]>(null)
  const [activeCard, setActiveCard] = useState<number | null>(null)
  const [userHasMoreActiveCards, setUserHasMoreActiveCards] =
    useState<boolean>(false)
 
  const [css, setCss] = useState("")
  const [title, setTitle] = useState("")
  const [desktopImage, setDesktopImage] = useState("")
  const [mobileImage, setMobileImage] = useState("")
  useEffect(()=> {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
      if(isLexus) {
        setCss("bg-lexusBlue shadow-leuxsCard")
        setTitle("Lexus EASY PAY")
        setMobileImage("/lexusImages/lexusImage01-mobile.jpg")
        setDesktopImage("/lexusImages/lexusImage01-desktop.jpg")
      } else {
        setTitle("TOYOTA EASY PAY")
        setCss("bg-toyotaRed shadow-toyotaCard")
        setMobileImage("/assets/images/riepilogo_pagamento-mobile.png")
        setDesktopImage("/assets/images/riepilogo_pagamento-desktop.png")
      }
    })
  }, [])
  const WorkOrderStatusMap = new Map([
    [
      'PAID',
      {
        name: 'pagata',
        bgcolor: 'bg-toyotaGray',
        color: 'text-primary',
      },
    ],
    [
      'ACTIVE',
      {
        name: 'da pagare',
        bgcolor: css,
        color: 'text-white',
      },
    ],
    [
      'NONACTIVE',
      {
        name: 'da pagare',
        bgcolor: css,
        color: 'text-white',
      },
    ],
    [
      'ERROR',
      {
        name: 'da pagare',
        bgcolor: css,
        color: 'text-white',
      },
    ],
    [
      'SUSPENDED',
      {
        name: 'Sospeso',
        bgcolor: 'bg-toyotaGray',
        color: 'text-primary',
      },
    ],
    
    [
      'REFUNDED',
      {
        name: 'Rimborsato',
        bgcolor: 'bg-toyotaGray',
        color: 'text-primary',
      },
    ],
    
  ])
  useEffect(() => {
    dispatch(requestUserCards())
    dispatch(requestUserActiveWorkOrders({}))
  }, [])

  useEffect(() => {
    cards.length > 1
      ? setUserHasMoreActiveCards(true)
      : setUserHasMoreActiveCards(false)
  }, [cards])

  useEffect(() => {
    if (!(cards.length >= 2)) {
      setActiveCard(cards[0]?.cardId)
    }
  }, [cards])

  useEffect(() => {
    if (workOrders.length > 0) {
      const activeWorkOrder = workOrders.find(
        workOrder => workOrder.workOrderId === workOrderId,
      )
      setSelectedWorkOrder(activeWorkOrder)
    }
  }, [workOrders, workOrderId])

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const openPaymentModal = () => setModalPayment(true)
  const closePaymentModal = () => setModalPayment(false)
  const handleOnDismissPaymentModal = () => setModalPayment(false)

  const handleOnDismissModal = () => setIsModalOpen(false)

  const handleOnChooseCard = () => {
    if (userHasMoreActiveCards) {
      openModal()
    }
  }

  const handleCardClick = (cardId: number) => {
    setActiveCard(cardId)
    closeModal()
  }

  const parseAmount = useCallback((amount: number | null | undefined) => {
    const parsedAmount = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount)
    return Number.isNaN(amount) ? '-' : parsedAmount
  }, [])

  const handleOnPay = () => {
    dispatch(
      requestPayInstalment({
        workOrderId: selectedWorkOrder?.workOrderId,
        paymentMethodId: activeCard,
        installmentId: Number(selectedWorkOrder?.detail?.lastPaid) + 1,
        dealerCode: selectedWorkOrder?.dealerCode,
      }),
    )
  }

  const handleOpenPaymentModal = () => {
    openPaymentModal()
  }


  const getCardDetailFromActiveCardId = useMemo(() => {
    return cards.find(card => card.cardId === activeCard)
  }, [cards, activeCard])

  return (
    <div className="mb-2 text-center w-full pb-5">
      <GridLayout
        cssProps="place-items-center"
        mobileUrl = {mobileImage}
        desktopUrl = {desktopImage}
      >
        <Title
          name= {title}
          cssProps="text-xl md:text-4xl md:text-left"
        />
        <Paragraf
          name="Riepilogo pagamento"
          cssProps="mb-2 text-xl text-center md:text-left"
        />
        <div className="mt-2">
          <>
            <div className="mt-2">
              <Paragraf
                name={`Ciao ${name} ${surname} `}
                cssProps={`remove-empty-content font-${urlisLexus[0]}  md:text-left capitalize `}
              />
              <h3 className={`mt-2 remove-empty-content font-${urlisLexus[0]} md:text-left `}>
                {selectedWorkOrder && 'workOrder' in selectedWorkOrder
                  ? 'Ordine'
                  : 'Fattura'}
              </h3>
              <p className={`remove-empty-content pb-4 font-${urlisLexus[0]}  md:text-left `}>
                {`${
                  selectedWorkOrder && 'workOrder' in selectedWorkOrder
                    ? selectedWorkOrder?.workOrder
                    : selectedWorkOrder?.invoice
                } - ${moment(selectedWorkOrder?.date).format('DD-MM-YYYY')} `}
              </p>
              <h3 className={`remove-empty-content font-${urlisLexus[0]} md:text-left `}>
                Totale
              </h3>
              <p className={`text-xl text-third font-${urlisLexus[0]} md:text-left `}>
                {parseAmount(selectedWorkOrder?.totalAmount)}
              </p>
            </div>
             {activeCard && (selectedWorkOrder?.status === 'IN_PROGRESS' || selectedWorkOrder?.status === 'PENDING') && (
              <div className="my-4">
                <Paragraf
                  name="Carta selezionata"
                  cssProps="text-center md:text-left"
                />
                {getCardDetailFromActiveCardId && (
                  <Card
                    cssProps="justify-center md:justify-start"
                    showStatus={false}
                    card={getCardDetailFromActiveCardId}
                    onCardClick={() => openModal()}
                  />
                )}
              </div>
            )}
            {!activeCard && cards.length >= 2 && (selectedWorkOrder?.status === 'IN_PROGRESS' || selectedWorkOrder?.status === 'PENDING') && (
                <CommonButton
                  onClick={handleOnChooseCard}
                  type="button"
                  name="Scegli la carta"
                />
              )}
            <div className="mx-2 md:mx-0 space-y-4 my-8 md:my-16">
              {selectedWorkOrder?.detail?.installment?.map(division => {
                return (
                  <div
                    key={division?.installmentId}
                    className="mt-5 flex justify-center max-w-xs"
                  >
                    <div
                      className={classNames(
                        'min-w-full flex justify-between py-6 px-8 rounded-3xl shadow-md',
                        WorkOrderStatusMap.get(division?.status).bgcolor,
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            `text-left  font-${urlisLexus[0]} uppercase`,
                            WorkOrderStatusMap.get(division?.status).color,
                          )}
                        >
                          Rata
                        </p>
                        <p
                          className={classNames(
                            `font-${urlisLexus[0]}`,
                            WorkOrderStatusMap.get(division?.status).color,
                          )}
                        >
                          {parseAmount(division?.amount?.value)}
                        </p>
                      </div>
                      {parseAmount(division?.penalty) != "0,00 €" &&
                      <div>
                        {console.log(parseAmount(division?.penalty))}
                        <p
                          className={classNames(
                            `text-left  font-${urlisLexus[0]} uppercase`,
                            WorkOrderStatusMap.get(division?.status).color,
                          )}
                        >
                        + Penale
                        </p>
                        <p
                          className={classNames(
                            `font-${urlisLexus[0]}`,
                            WorkOrderStatusMap.get(division?.status).color,
                          )}
                        >
                          {parseAmount(division?.penalty)}
                        </p>
                      </div>
                      }
                      <div>
                        <p
                          className={classNames(
                            `font-${urlisLexus[0]} uppercase`,
                            WorkOrderStatusMap.get(division?.status).color,
                          )}
                        >
                          {WorkOrderStatusMap.get(division?.status).name}
                        </p>
                        <p
                          className={classNames(
                            `font-${urlisLexus[0]} `,
                            WorkOrderStatusMap.get(division?.status)?.color ??
                              division?.status,
                          )}
                        >
                          {division.paymentDate
                            ? moment(division.paymentDate).format('DD-MM-YYYY')
                            : moment(division.date).format('DD-MM-YYYY') ===
                              moment().format('DD-MM-YYYY')
                            ? 'Oggi'
                            : moment(division.date).format('DD-MM-YYYY')}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="mt-10 space-y-2">
              {(selectedWorkOrder?.status === 'IN_PROGRESS' || selectedWorkOrder?.status === 'PENDING' ) && (
                <CommonButton
                  disabled={!activeCard}
                  name="Paga ora"
                  onClick={handleOpenPaymentModal}
                  cssType='fill-in-white'
                />
              )}
              <GoBackButton
                cssProps={`py-2 px-5 md:px-0 flex space-x-2 justify-center md:justify-start items-center font-${urlisLexus[0]} mx-auto md:mx-0 w-64  `}
                handleClick={() => navigate(-1)}
              />
            </div>
          </>
        </div>
      </GridLayout>
      <BottomModal isOpen={modalPayment} onDismiss={handleOnDismissPaymentModal}>
        <div className="text-center space-y-5 py-3">
          <Title name="Procedere con il pagamento anticipato?" />

          <CommonButton
            name="Continua"
            cssType='fill-in-white'
            onClick={handleOnPay}
          />
          <CommonButton
            name="Annulla"
            cssType='fill-in-white'
            onClick={closePaymentModal}
          />
        </div>
      </BottomModal>
      <BottomModal isOpen={isModalOpen} onDismiss={handleOnDismissModal}>
        <div className="text-center space-y-5 py-3">
          <Title name="Scegli quale carta con cui pagare la rata" />
          <Paragraf name="hai più di una carta attiva, scegli quale usare" />
          {cards &&
            cards.map(card => (
              <div
                key={card.cardId}
                className="flex flex-col px-2 justify-center items-center"
              >
                <Card
                  cssProps="justify-center"
                  showStatus={false}
                  card={card}
                  onCardClick={() => handleCardClick(card.cardId)}
                />
                <hr />
              </div>
            ))}
          <CommonButton
            name="Annulla"
            cssType='fill-in-white'
            onClick={closeModal}
          />
        </div>
      </BottomModal>
    </div>
  )
}

export default PaymentsSituationDetail
