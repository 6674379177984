import BottomModal from 'components/BottomModal'
import CommonButton from 'components/CommonButton'
import FAB from 'components/FAB'
import Paragraf from 'components/Paragraf'
import Stepper from 'components/Stepper'
import Title from 'components/Title'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { populateWorkOrder, selectWorkOrder } from 'store/slices/appMiscSlice'

const STEPS = ['Dettaglio ordine', 'checkout', 'fine transazione']
const CURRENT_STEP = 3
const TYP: React.VFC = () => {
  const [paymentType] = useState(
    localStorage.getItem('paymentType') === 'split',
  )
  const workOrder = useSelector(selectWorkOrder)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    dispatch(populateWorkOrder({ workOrder, status: 'completed' }))
  }, [])

  const handleNewOperation = async () => {
    await dispatch(populateWorkOrder({ workOrder: null, status: null }))
    navigate('/')
  }
  const handleOpenStepper = () => setIsModalOpen(true)

  const handleOnDismissModal = () => {
    setIsModalOpen(false)
  }
  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Title name="Pagamento approvato" cssProps="text-2xl" />
      <Paragraf
        cssProps="text-center"
        name="Congratulazioni, l'operazione è avvenuta con successo!"
      />
      <div className="mt-8">
        <CommonButton
          type="button"
          name="Nuova operazione"
          onClick={handleNewOperation}
        />
      </div>
      {paymentType && (
        <div className="mt-8">
          <CommonButton
            cssProps="bg-secondary border-2 border-primary rounded-full py-2 px-5 w-64 text-base text-third block mx-auto"
            type="button"
            name="Area Cliente"
            onClick={() => navigate('/split-payment-overview')}
          />
        </div>
      )}
      <FAB onClick={handleOpenStepper} />
      <BottomModal isOpen={isModalOpen} onDismiss={handleOnDismissModal}>
        <Stepper stepsLabels={STEPS} currentStep={CURRENT_STEP} />
      </BottomModal>
    </div>
  )
}

export default TYP
