import { LOCAL_STORAGE_TOKEN_KEY } from 'commons/constants'
import { LocalStorageObject } from 'commons/types/localStorageObject'

export const LocalStorageService = {
  getObject: (): LocalStorageObject =>
    JSON.parse(
      sessionStorage.getItem(LOCAL_STORAGE_TOKEN_KEY),
    ) as LocalStorageObject,
  addObject: (object: any) => {
    const existing = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_TOKEN_KEY))
    sessionStorage.setItem(
      LOCAL_STORAGE_TOKEN_KEY,
      JSON.stringify({ ...existing, ...object }, undefined, 2),
    )
  },
  removeObject: (propertyName: string) => {
    const object = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_TOKEN_KEY))
    if (Array.isArray(propertyName)) {
      propertyName.forEach(name => {
        delete object[name]
      })
    } else delete object[propertyName]
    sessionStorage.setItem(
      LOCAL_STORAGE_TOKEN_KEY,
      JSON.stringify(object, undefined, 2),
    )
  },
}
