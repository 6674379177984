import { AxiosResponse } from 'axios'
import { easyPayInstance } from './AxiosInstance'

let appId = "TOYOTA"
let url = window.location.href;

export const uploadFile = (data, onUploadProgress, type) => {
  const formData = new FormData()
  formData.append('wordOrder', data)
  return easyPayInstance.post(`/${appId}/workOrder/${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
