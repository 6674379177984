import React, { forwardRef } from 'react'
import LabelTailwind from './Label'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
    urlisLexus=isLexus
  })

const Input = (
  {
    cssProps,
    customLabel,
    customCssPropsLabel,
    error,
    name,
    type,
    onBlur,
    register,
    validationOptions = {},
    ...rest
  },
  _ref,
) => {
  return (
    <>
      {customLabel && (
        <div className="mb-2">
          <LabelTailwind cssProps={customCssPropsLabel} name={customLabel} />
        </div>
      )}
      <div>
        <input
          {...register(name, { ...validationOptions })}
          {...rest}
          type={type}
          className={ cssProps ?? `border-2 rounded w-full h-12 px-2 font-${urlisLexus[0]} text-primary`}
          name={name}
          onBlur={onBlur}
        />
      </div>
      {error && (
        <div className="mb-2">
          <LabelTailwind
            name={error?.message}
            cssProps={`text-third font-${urlisLexus[0]}`}
          />
        </div>
      )}
    </>
  )
}

export default forwardRef(Input)
