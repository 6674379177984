/* eslint-disable no-underscore-dangle */
export const __DEV__ = process.env.NODE_ENV === 'development'
export const __PROD__ = process.env.NODE_ENV === 'production'
export const LOCAL_STORAGE_TOKEN_KEY = 'easyPay'
export const INVALID_CARD_MESSAGE =
  'Gentile cliente, la carta inserita non è utilizzabile per l’acquisto. La modalità di pagamento dilazionato è riservata all’utilizzo di sole carte di credito (no Bancomat e/o carta prepagata). Ti chiediamo quindi di inserire una carta di credito valida (Visa, MasterCard, American Express), o di passare alla modalità di pagamento in un’unica soluzione'
export const EXPIRED_CARD_MESSAGE =
  'Gentile cliente, la tua carta di credito scadrà fra meno di 6 mesi. Per usufruire della modalità di pagamento dilazionato ti preghiamo di inserire una carta con validità superiore ai 6 mesi, o di passare alla modalità di pagamento in un’unica soluzione'
export const CARD_DECLINED_INS_FUNDS_MESSAGE =
'Gentile cliente, la sua carta non ha fondi sufficienti per completare l\'acquisto'
export const CARD_DECLINED_INVALID_AMOUNT_MESSAGE =
'Gentile cliente, L\'importo del pagamento non è valido o supera l\'importo consentito per la carta'
export const CARD_DECLINED_SUSPECTED_MESSAGE =
'Gentile cliente La carta è stata rifiutata in quanto sospetta'
export const CARD_DECLINED_TRY_AGAIN_MESSAGE =
'Gentile cliente il pagamento non è andato a buon fine: riprovare. Se il pagamento continua a non essere accettato, la preghiamo di contattare l\'emittente della sua carta'
export const CARD_DECLINED_LIMIT_EXCEEDED_MESSAGE =
'Gentile cliente, sono stati eseguiti troppi tentativi falliti per questa carta nelle ultime 24h'
export const CARD_DECLINED_MESSAGE=
'Gentile cliente, la carta non è stata accettata le consigliamo di contattare l\'emittente della sua carta per ulteriori informazioni'
export const GENERIC_ERROR_MESSAGE =
'Gentile cliente, il pagamento non è stato eseguito, la preghiamo di contattare il supporto tecnico'
export const INVALID_AMOUNT_MESSAGE =
'Gentile cliente l\'importo è troppo grande per essere processato'
export const REJECTED_CARD_BY_AUTH_MESSAGE =
'Gentile cliente, l\'utenticazione non è riuscita, per completare questo pagamento è necessario autenticarsi durante l\'esecuzione della transazione'
export const CARD_DECLINED_INVALID_CARD_MESSAGE =
'Gentile cliente, la preghiamo di reinserire i dati della carta'
export const MAXIMUM_PERMITTED_AMOUNT_MESSAGE=
'Attenzione! Non possiamo inserire questo Ordine di Lavoro in quanto hai altre dilazioni in corso e supereresti il limite massimo consentito di € 1.500'

