import ChooseOption from 'containers/ChoosenOption'
import ChoosePictureExplanation from 'containers/ChoosePictureExplanation'
import CustomerArea from 'containers/CustomerArea'
import Profile from 'containers/CustomerArea/Profile'
import UpdateEmail from 'containers/CustomerArea/UpdateEmail'
import UpdatePassword from 'containers/CustomerArea/UpdatePassword'
import UpdatePhone from 'containers/CustomerArea/UpdatePhone'
import ErrorPage from 'containers/ErrorPage/ErrorPage'
import Login from 'containers/Login'
import PaymentDirect from 'containers/PaymentDirect'
import RecapPage from 'containers/RecapPage'
import Registration from 'containers/Registration'
import EmailSubmission from 'containers/Registration/EmailSubmission'
import PasswordRecovery from 'containers/Registration/PasswordRecovery'
import PasswordSubmission from 'containers/Registration/PasswordSubmission'
import PersonalInfoSubmission from 'containers/Registration/PersonalInfoSubmission'
import PhoneSubmission from 'containers/Registration/PhoneSubmission'
import SplitPayment from 'containers/SplitPayment'
import TYP from 'containers/TYP/TYP'
import TYPFinancial from 'containers/TYPFinancial/TYPFinancial'
import UploadFilePage from 'containers/UploadFilePage'
import MainLayout from 'layout/MainLayout'
import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import PaymentMethod from 'containers/CustomerArea/PaymentMethod'
import Ko from 'containers/Ko'
import KoFinancial from 'containers/KoFinancial'
import RoleGuard from 'guards/RoleGuard'
import {
  CHOOSE_OPTION,
  CUSTOMER_AREA_HOMEPAGE,
  CUSTOMER_AREA_INFORMATIVE_DOCUMENTS,
  // CUSTOMER_AREA_PAY_INSTALMENT,
  // CUSTOMER_AREA_PAYMENTS_MADE,
  CUSTOMER_AREA_PAYMENTS_MADE_DETAIL,
  CUSTOMER_AREA_PAYMENTS_SITUATION,
  CUSTOMER_AREA_PROFILE,
  CUSTOMER_AREA_UPDATE_EMAIL,
  CUSTOMER_AREA_UPDATE_PASSWORD,
  CUSTOMER_AREA_UPDATE_PHONE,
  DIRECT_PAYMENT,
  EMAIL_SUBMISSION,
  ERROR,
  KO,
  KO_FINANCIAL,
  LOGIN,
  PASSWORD_RECOVERY,
  PASSWORD_SUBMISSION,
  PAYMENT_METHOD,
  PERSONAL_INFO_SUBMISSION,
  PHONE_SUBMISSION,
  PICTURE_EXPLANATION,
  RECAP,
  REGISTRATION,
  SPLIT_PAYMENT,
  SPLIT_PAYMENT_OVERVIEW,
  THANK_YOU,
  THANK_YOU_FINANCIAL,
} from './constants'
// import PayInstalment from 'containers/CustomerArea/PayInstalment'
// import PaymentsMade from 'containers/CustomerArea/PaymentsMade'
import { EXPIRED_CARD_MESSAGE, INVALID_CARD_MESSAGE, CARD_DECLINED_INS_FUNDS_MESSAGE, CARD_DECLINED_INVALID_AMOUNT_MESSAGE,CARD_DECLINED_SUSPECTED_MESSAGE, CARD_DECLINED_TRY_AGAIN_MESSAGE, CARD_DECLINED_MESSAGE, CARD_DECLINED_LIMIT_EXCEEDED_MESSAGE, GENERIC_ERROR_MESSAGE, INVALID_AMOUNT_MESSAGE, REJECTED_CARD_BY_AUTH_MESSAGE, CARD_DECLINED_INVALID_CARD_MESSAGE, MAXIMUM_PERMITTED_AMOUNT_MESSAGE} from 'commons/constants'
import InformativeDocuments from 'containers/CustomerArea/InformativeDocuments'
import PaymentsMadeDetail from 'containers/CustomerArea/PaymentsMadeDetail'
import PaymentsSituationOverview from 'containers/CustomerArea/PaymentsSituation'
import PaymentsSituationDetail from 'containers/CustomerArea/PaymentsSituation/Detail'

const errorCodesMap = new Map([
  [
    'default',
    'Non è stato possibile effettuare il pagamento, Per favore riprova più tardi',
  ],
  ['INVALID_CARD', INVALID_CARD_MESSAGE],
  ['EXPIRED_CARD', EXPIRED_CARD_MESSAGE],
  ['CARD_DECLINED_INS_FUNDS', CARD_DECLINED_INS_FUNDS_MESSAGE],
  ['CARD_DECLINED_INVALID_AMOUNT', CARD_DECLINED_INVALID_AMOUNT_MESSAGE],
  ['CARD_DECLINED_SUSPECTED', CARD_DECLINED_SUSPECTED_MESSAGE],
  ['CARD_DECLINED_TRY_AGAIN', CARD_DECLINED_TRY_AGAIN_MESSAGE],
  ['CARD_DECLINED', CARD_DECLINED_MESSAGE],
  ['CARD_DECLINED_LIMIT_EXCEEDED', CARD_DECLINED_LIMIT_EXCEEDED_MESSAGE],
  ['GENERIC_ERROR', GENERIC_ERROR_MESSAGE],
  ['INVALID_AMOUNT', INVALID_AMOUNT_MESSAGE],
  ['REJECTED_CARD_BY_AUTH', REJECTED_CARD_BY_AUTH_MESSAGE],
  ['CARD_DECLINED_INVALID_CARD', CARD_DECLINED_INVALID_CARD_MESSAGE],
  ['MAXIMUM_PERMITTED_AMOUNT',MAXIMUM_PERMITTED_AMOUNT_MESSAGE],
])

const Routes: React.FC = () => {
  const publicRoutes = {
    path: '',
    element: <MainLayout />,
    children: [
      { path: '', element: <UploadFilePage /> },
      { path: `${PICTURE_EXPLANATION}`, element: <ChoosePictureExplanation /> },
      { path: `${RECAP}`, element: <RecapPage /> },
      { path: `${CHOOSE_OPTION}`, element: <ChooseOption /> },
      { path: `${LOGIN}`, element: <Login /> },
      { path: `${PASSWORD_RECOVERY}`, element: <PasswordRecovery /> },
      { path: `${DIRECT_PAYMENT}`, element: <PaymentDirect /> },
      { path: `${SPLIT_PAYMENT_OVERVIEW}`, element: <SplitPayment /> },
      { path: `${SPLIT_PAYMENT}`, element: <SplitPayment /> },
      { path: `${THANK_YOU}`, element: <TYP /> },
      { path: `${THANK_YOU_FINANCIAL}`, element: <TYPFinancial /> },
      { path: `${ERROR}`, element: <ErrorPage /> },
      { path: `${KO}`, element: <Ko errorMap={errorCodesMap} /> },
      {
        path: `${KO_FINANCIAL}`,
        element: <KoFinancial errorMap={errorCodesMap} />,
      },
      { path: '*', element: <Navigate to="/" /> },
    ],
  }

  const registrationRoutes = {
    path: REGISTRATION,
    element: <MainLayout showRegistrationStepper />,
    children: [
      { path: '', element: <Registration /> },
      { path: `${EMAIL_SUBMISSION}`, element: <EmailSubmission /> },
      { path: `${PHONE_SUBMISSION}`, element: <PhoneSubmission /> },
      {
        path: `${PERSONAL_INFO_SUBMISSION}`,
        element: <PersonalInfoSubmission />,
      },
      { path: `${PASSWORD_SUBMISSION}`, element: <PasswordSubmission /> },
      { path: '*', element: <Navigate to={`/${REGISTRATION} `} /> },
    ],
  }

  const customerRoutes = {
    path: 'customer-area',
    element: (
      <RoleGuard>
        <MainLayout />
      </RoleGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={`/customer-area/${CUSTOMER_AREA_HOMEPAGE} `} />,
      },
      { path: `${CUSTOMER_AREA_HOMEPAGE}`, element: <CustomerArea /> },
      { path: `${CUSTOMER_AREA_PROFILE}`, element: <Profile /> },
      { path: `${PAYMENT_METHOD}`, element: <PaymentMethod /> },
      // { path: `${CUSTOMER_AREA_PAY_INSTALMENT}`, element: <PayInstalment /> },
      // { path: `${CUSTOMER_AREA_PAYMENTS_MADE}`, element: <PaymentsMade /> },
      {
        path: `${CUSTOMER_AREA_PAYMENTS_MADE_DETAIL}/:workOrderId/:instalmentId`,
        element: <PaymentsMadeDetail />,
      },
      {
        path: `${CUSTOMER_AREA_PAYMENTS_SITUATION}`,
        element: <PaymentsSituationOverview />,
      },
      {
        path: `${CUSTOMER_AREA_PAYMENTS_SITUATION}/:workOrderId`,
        element: <PaymentsSituationDetail />,
      },
      {
        path: `${CUSTOMER_AREA_UPDATE_EMAIL}`,
        element: <UpdateEmail />,
      },
      {
        path: `${CUSTOMER_AREA_UPDATE_PHONE}`,
        element: <UpdatePhone />,
      },
      {
        path: `${CUSTOMER_AREA_UPDATE_PASSWORD}`,
        element: <UpdatePassword />,
      },
      {
        path: `${CUSTOMER_AREA_INFORMATIVE_DOCUMENTS}`,
        element: <InformativeDocuments />,
      },
      {
        path: '*',
        element: <Navigate to={`/customer-area/${CUSTOMER_AREA_HOMEPAGE} `} />,
      },
    ],
  }

  const routing = useRoutes([publicRoutes, registrationRoutes, customerRoutes])
  return <>{routing}</>
}

export default Routes
