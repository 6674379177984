import { AxiosResponse } from 'axios'
import { UserInfo } from 'commons/dto/userInfo'
import { KeycloakToken } from 'commons/types/keycloakToken'
import { keycloakInstance } from 'Axios/AxiosInstance'
import { LocalStorageService } from 'utils/LocalStorageService'
import { loadConstants } from 'api/api'

export const getToken = async ({
  email,
  password,
}): Promise<AxiosResponse<KeycloakToken>> => {
  const body = new URLSearchParams()
  const constants = await loadConstants()
  body.append('grant_type', constants.GRANT_TYPE)
  body.append('client_secret', constants.CLIENT_SECRET)
  body.append('client_id', constants.CLIENT_ID)
  body.append('username', email)
  body.append('password', password)

  return keycloakInstance.post(`/token`, body)
}

export const refreshToken = async (
  password: string,
): Promise<AxiosResponse<KeycloakToken>> => {
  const { token } = LocalStorageService.getObject()
  if (!token) throw new Error('NO TOKEN FOUND')
  const { email, refresh_token } = token
  const body = new URLSearchParams()
  const constants = await loadConstants()
  body.append('grant_type', constants.GRANT_TYPE)
  body.append('client_secret', constants.CLIENT_SECRET)
  body.append('client_id', constants.CLIENT_ID)
  body.append('username', email)
  body.append('password', password)
  body.append('refresh_token', refresh_token)

  return keycloakInstance.post(`/token`, body)
}

export const getUserInfo = (token: string): Promise<AxiosResponse<UserInfo>> =>
  keycloakInstance.post(
    `/userinfo`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
