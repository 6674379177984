import React, { forwardRef } from 'react'

type PinPadProps = {
  value: string
  onChange: (value: string) => void
}

type Ref = HTMLButtonElement
const PinPad = ({ value, onChange }, ref) => {
  const handleChange = item => {
    const newValue =
      item === '<<' ? value.substring(0, value.length - 1) : value.concat(item)
    onChange(newValue.length > 6 ? `${value}` : `${newValue}`)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
    e.target.value.length >= 6 && e.target.form.requestSubmit()
  }

  return (
    <div className="">
      <div className="flex gap-2 justify-center py-8">
        <div>
          <input
            type="tel"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            className="border-2 rounded w-full h-12 px-2"
            value={value}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 grid-rows-4 gap-6 place-items-center">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '<<'].map(item => (
          <button
            type="submit"
            ref={ref}
            key={item}
            onClick={() => handleChange(item)}
            className={`${
              item === 0 && 'col-start-2'
            } w-14 h-14 border border-black rounded-full text-center font-bold text-xl text-black bg-gray-200 bg-opacity-40 hover:bg-opacity-100 hover:bg-blue-400 hover:border-blue-800 hover:text-blue-900 `}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  )
}

export default forwardRef<Ref, PinPadProps>(PinPad)
