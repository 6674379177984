import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
// import * as FiscalCodeParser from 'codice-fiscale-js'
import { addressApi, userApi } from 'api/easypay'
import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import Label from 'components/Label'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggleLoading } from 'store/slices/appMiscSlice'
import {
  decrementCurrentStepBy,
  incrementCurrentStep,
  selectStepAmount,
  setCurrentStep
} from 'store/slices/registrationStepper'
import { selectTempUser } from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'
import * as Yup from 'yup'
import { isLexusCss } from 'utils/utils'

let urlisLexus = []
let url = window.location.href;
isLexusCss(url).then(isLexus => {
    urlisLexus=isLexus
})

const italianFiscalCodeRegex =
  /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/
const postalCodeRegex = /\d{5}/

const PersonalInfoSubmission: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [cf, setCf] = useState(null)
  const { id: tempUserId } = useSelector(selectTempUser)
  const totalStep = useSelector(selectStepAmount);

  const goBack = () => {
    if(totalStep == 6){
      dispatch(setCurrentStep(3));
    } else {
      dispatch(setCurrentStep(1));
    }
    navigate(-1)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo richiesto'),
    surname: Yup.string().required('Campo richiesto'),
    fiscalCode: Yup.string()
      .required('Campo richiesto')
      .matches(italianFiscalCodeRegex, 'C.F non valido'),
    nationality: Yup.string().required('Campo richiesto'),
    postalCode: Yup.string()
      .required('Campo richiesto')
      .matches(postalCodeRegex, 'Campo non valido'),
    country: Yup.string().required('Campo richiesto'),
    city: Yup.string().required('Campo richiesto'),
    streetNumber: Yup.number()
      .transform(value => (Number.isNaN(value) ? undefined : value))
      .required('Campo richiesto')
      .positive('Campo non valido'),
    streetName: Yup.string().required('Campo richiesto'),
    birthPlace: Yup.string().required('Campo richiesto'),
    birthDate: Yup.string().required('Campo richiesto'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  })

  const onSubmit = async ({
    birthDate,
    birthPlace,
    city,
    country,
    fiscalCode,
    gender,
    name,
    nationality,
    postalCode,
    streetName,
    streetNumber,
    surname,
  }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      try {
        await userApi.insertUser(
          tempUserId,
          birthDate,
          gender,
          name,
          nationality,
          birthPlace,
          surname,
          fiscalCode,
        )
      } catch (e) {
        console.error(e)
        console.log(e.response)
        const message = JSON.parse(e?.response?.data?.messages[0]?.message)
        if (message.code === 'USED_TAX_CODE') {
          printToast.error(message.message, 6000)
        } else {
          printToast.error(
            'Qualcosa è andato storto, prova a reinserire i dati',
            5000,
          )
        }
        return
      }
      await addressApi.insertAddress({
        entityId: tempUserId,
        defaultFlag: true,
        city,
        country,
        postalCode,
        streetAddress: streetName,
        streetNumber,
      })
      navigate('/registration/password-submission')
    } catch (e) {
      console.error(e)
      printToast.error()
    } finally {
      dispatch(incrementCurrentStep())
      dispatch(toggleLoading({ value: false }))
    }
  }

  const handleOnBlur = inputName => {
    trigger(inputName)
  }

  const parseFiscalCode = fiscalCode => {
    const FiscalCodeParser = require('codice-fiscale-js')
    const parsedFiscalCode = new FiscalCodeParser(fiscalCode).toJSON()
    setCf(parsedFiscalCode)
  }

  const handleFiscalCodeChange = () => {
    handleOnBlur('fiscalCode')
    parseFiscalCode(getValues('fiscalCode'))
  }

  useEffect(() => {
    if (cf) {
      setValue('birthPlace', cf?.birthplace ?? '', { shouldDirty: true })
      setValue('gender', cf?.gender ?? '', { shouldDirty: true })
      setValue(
        'birthDate',
        new Date(cf.year, cf.month - 1, cf.day + 1).toISOString().split('T')[0],
        { shouldDirty: true },
      )
    }
  }, [cf])

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          name="name"
          error={errors?.name}
          customLabel="Nome"
          autoComplete="given-name"
          onBlur={() => handleOnBlur('name')}
          cssProps={`block w-full border-2 rounded w-full h-12 px-2 font-${urlisLexus[0]} text-primary mb-4 `}
        />

        <Input
          register={register}
          name="surname"
          error={errors?.surname}
          customLabel="Cognome"
          autoComplete="family-name"
          onBlur={() => handleOnBlur('surname')}
          cssProps={`block w-full border-2 rounded w-full h-12 px-2 font-${urlisLexus[0]} text-primary mb-4 `}
        />

        <Input
          register={register}
          name="fiscalCode"
          error={errors?.fiscalCode}
          onChange={e => {
            setValue('fiscalCode', e.target.value.toUpperCase())
          }}
          customLabel="Codice fiscale"
          onBlur={() => {
            handleFiscalCodeChange()
          }}
          cssProps={`block w-full border-2 rounded w-full h-12 px-2 font-${urlisLexus[0]} text-primary mb-4 `}
        />
        <div className="flex justify-between gap-2 space-x-4 mb-5">
          <div>
            <Input
              register={register}
              name="nationality"
              autoComplete="on"
              onBlur={() => handleOnBlur('nationality')}
              error={errors?.nationality}
              customLabel="Nazionalità"
            />
          </div>

          <div>
            <div className="mb-2">
              <Label
                name="Seleziona genere"
                cssProps={`font-${urlisLexus[0]} text-primary truncate `}
              />
            </div>
            <select
              {...register('gender')}
              className="form-select appearance-none block w-full h-12 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="M">Maschio</option>
              <option value="F">Femmina</option>
            </select>
            {errors?.gender && (
              <div className="mb-2">
                <Label
                  name={errors?.gender.message}
                  cssProps={`text-third font-${urlisLexus[0]}  `}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between gap-2 space-x-4 mb-5">
          <div>
            <Input
              register={register}
              name="birthDate"
              autoComplete="bday"
              onBlur={() => handleOnBlur('birthDate')}
              type="date"
              error={errors?.birthDate}
              customLabel="Data di nascita"
              customCssPropsLabel="truncate"
            />
          </div>

          <div>
            <Input
              register={register}
              name="birthPlace"
              autoComplete="on"
              onBlur={() => handleOnBlur('birthPlace')}
              error={errors?.birthPlace}
              customLabel="Luogo di nascita"
            />
          </div>
        </div>
        <div className="flex justify-between gap-2 space-x-4 mb-5">
          <div>
            <Input
              register={register}
              name="streetName"
              autoComplete="address-line1"
              onBlur={() => handleOnBlur('streetName')}
              error={errors?.streetName}
              customLabel="Via"
            />
          </div>

          <div>
            <Input
              register={register}
              name="streetNumber"
              error={errors?.streetNumber}
              customLabel="Numero civico"
              onBlur={() => handleOnBlur('streetNumber')}
            />
          </div>
        </div>
        <div className="flex justify-between gap-2 space-x-4 mb-5">
          <div>
            <Input
              register={register}
              name="country"
              autoComplete="country-name"
              error={errors?.country}
              onBlur={() => handleOnBlur('country')}
              customLabel="Nazione"
            />
          </div>

          <div>
            <Input
              register={register}
              name="city"
              error={errors?.city}
              onBlur={() => handleOnBlur('city')}
              customLabel="Città"
            />
          </div>

          <div>
            <Input
              register={register}
              name="postalCode"
              autoComplete="postal-code"
              error={errors?.postalCode}
              onBlur={() => handleOnBlur('postalCode')}
              customLabel="Cap"
            />
          </div>
        </div>
        <div className="space-y-8">
          <CommonButton type="submit" name="Continua" disabled={!isValid} />
          <CommonButton
            onClick={goBack}
            type="button"
            name="Torna indietro"
            cssType='fill-in-white'
          />
        </div>
      </form>
    </div>
  )
}

export default PersonalInfoSubmission
