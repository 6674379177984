import React from 'react'
import { Card } from 'store/slices/userSlice'
import { BsCheckLg, BsHourglass, BsX } from 'react-icons/bs'
import { FcExpired } from 'react-icons/fc'
import Paragraf from 'components/Paragraf'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const statusMap = new Map([
  [
    'ACTIVE',
    {
      borderColor: 'border-green-500',
      icon: <BsCheckLg className="" size="1em" color="#17B169" />,
      text: 'Attiva',
    },
  ],
  [
    'SUSPENDED',
    {
      borderColor: 'border-yellow-500',
      icon: <BsHourglass className="flex-grow" size="1em" color="#cfb20e" />,
      text: 'Sospesa',
    },
  ],
  [
    'CANCELED',
    {
      borderColor: 'border-red-500',
      icon: <BsX className="flex-grow" size="1em" color="#cf150e" />,
      text: 'Cancellata',
    },
  ],
  [
    'EXPIRED',
    {
      borderColor: 'border-gray-500',
      icon: <FcExpired className="flex-grow" size="1em" />,
      text: 'Scaduta',
    },
  ],
])

const CardStatus: React.VFC<{ card: Card }> = ({ card }) => {
  return (
    <>
      {card.status && (
        <div
          className={`border-2 ${
            statusMap.get(card.status)?.borderColor
          } rounded-full py-2 px-5 text-base text-green-500 font-bold flex justify-center items-center space-x-2 `}
        >
          <Paragraf
            name={statusMap.get(card.status)?.text}
            cssProps={`font-${urlisLexus[0]} `}
          />

          {statusMap.get(card.status)?.icon}
        </div>
      )}
    </>
  )
}

export default CardStatus
